/* eslint-disable import/no-cycle */
// import http from '../plugins/http';
import request from './index';

// const key = 'https://www.metalifegame.org/api'; // 生产
// const key = ''; // 测试

// const api = http[key] || http.instance(key); // api modules.test

class Service {
  constructor() {
    this.api = request;
  }
  // 公共表格数据请求方法
  // publicGet(url, params) {
  //   return request.get(url, {
  //     params
  //   })
  // }

  // 公共post方法
  // publicPost(url, params) {
  //   return request.post(url, params)
  // }
}
export default new Service();


// // 获取账户基本信息
// export function fetchAccountInfo(params, options) {
//   return request.get('/api/v1/account/info', params, options);
// }

// // 文件上传
// export function fetchUpload(params, options) {
//   return request.post('/api/v1/file/upload', params, options);
// }

// // 修改头像 username
// export function fetchUpdateInfo(params, options) {
//   return request.post('/api/v1/account/updateInfo', params, options);
// }

// 添加链上卖单信息
export function fetchChainOrderExecutedAdd(params, options) {
  return request.post('/api/admin/chainOrderExecuted/add', params, options);
}
