/* eslint-disable import/prefer-default-export */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

const StyleBase = styled.div``

const TokenText = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  display: flex;
  justify-content: start;
  main {
    width: 100%;
    .main {
      width: 100%;
      .module1 {
        .text1 {
          text-align: center;
          font-size: ${({isMobile}) => isMobile ?  '64px' : '32px' };
          font-family: Nirmala UI-Bold;
          font-weight: 400;
          color: #FFFFFF;
        }
        .text2-box {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: 29px;
          .text2-1 {
            font-size: ${({isMobile}) => isMobile ?  '20px' : '16px' };
            font-family: Nirmala UI-Bold;
            font-weight: 400;
            color: #FFFFFF;
          }
          .text2-2 {
            font-size:  ${({isMobile}) => isMobile ?  '80px' : '24px' };
            font-family: Nirmala UI-Bold;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }
  }
`
const Stake = styled(StyleBase)<{isMobile?: boolean, tabIndex?: number}>`
  margin-top: 32px;
  position: relative;
  display: flex;
  justify-content: start;
  main {
    width: 100%;
    .main { 
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
`

const HarvestModal = styled(StyleBase)<{isMobile?: boolean}>`
  width: 544px;
  height: 393px;
  background: #232323;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  box-sizing: border-box;
  padding: 0 68px;
  .title {
    padding-top: 68px;
    font-size: 20px;
    font-family: Nirmala UI-Regular, Nirmala UI;
    font-weight: 400;
    color: #EEEEEE;
    line-height: 32px;
    text-align: center;
  }
  .token {
    margin-top: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: Nirmala UI-Regular, Nirmala UI;
    font-weight: 400;
    color: #EEEEEE;
    line-height: 32px;
  }
  .btn {
    width: ${({isMobile}) => isMobile ?  '' : '100%' };
    margin-top: 16px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
      height: 56px;
      background: #FFFFFF;
      border-radius: 12px 12px 12px 12px;
      font-size: 16px;
      font-family: Nirmala UI-Regular, Nirmala UI;
      font-weight: 400;
      color: #000000;
    }
  }
  .btn-change {
    margin-top: ${({isMobile}) => isMobile ?  '86px' : '41px' };
    display: flex;
    width: ${({isMobile}) => isMobile ?  '400px' : '100%' };
    justify-content: space-between;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'column' };      
    button {
      width: ${({isMobile}) => isMobile ?  '170px' : '100%' };
      height: 56px;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
    }
    .Cancle {
      background: #F8C6C6;
      color: #B11213;
      margin-bottom: ${({isMobile}) => isMobile ?  '' : '15px' };
    }
  }
`

const StyleContainer =  styled(StyleBase)<{isMobile?: boolean }>`
    padding: 14px;
    margin-top: 36px;
    button {
        border-radius: ${({isMobile}) => isMobile ?  '24px' : '16px' };
    }
`
const Wrapper1 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:  0px;
`

const Card =  styled(StyleBase)<{isMobile?: boolean }>`
    width: 100%;
    background: #232323;
    padding:  ${({isMobile}) => isMobile ?  '24px 56px' : '24px 16px' };
    button {
        border-radius: ${({isMobile}) => isMobile ?  '24px' : '16px' };
    }
    .line {
      margin: 12px 0;
      height: 1px;
      border-bottom: 1px solid #707070;
    }
    .title {
      width: 100%;
      font-size: 16px;
      font-family: Nirmala UI-Regular, Nirmala UI;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      text-align: start;
    }
    .title-x {
      width: 100%;
      font-size: 12px;
      font-family: Nirmala UI-Regular, Nirmala UI;
      font-weight: 400;
      color: #8A8A8A;
      line-height: 21px;
      text-align: start;
    }
    .content-box {
      margin-top: ${({isMobile}) => isMobile ?  '16px' : '30px' };
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 16px;
      font-family: Nirmala UI-Regular, Nirmala UI;
      font-weight: 400;
      line-height: 32px;
      .new-img {
        width: ${({isMobile}) => isMobile ?  '100%' : '100%' };
        height: ${({isMobile}) => isMobile ?  '250px' : '' };
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: ${({isMobile}) => isMobile ?  '' : '' };
        .img-n {
          width: ${({isMobile}) => isMobile ?  '210px' : '105px' };
          height: ${({isMobile}) => isMobile ?  '210px' : '105px' };
        }

      }
      .img {
        width: ${({isMobile}) => isMobile ?  '' : '100%' };
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: ${({isMobile}) => isMobile ?  '480px' : '100%' };
          height: auto;
        }
      }
      .content {
        width: 100%;
        margin-top: 16px;
        .text-box {
          display: flex;
          justify-content: space-between;
          align-items: center; 
        }
        .btn {
          margin-top: 6px;
        }
      }
    }
`

const BigCard =  styled(StyleBase)<{isMobile?: boolean }>`
    width: 100%;
    background: #232323;
    padding:  ${({isMobile}) => isMobile ?  '24px 56px' : '24px 16px' };
    button {
        border-radius: ${({isMobile}) => isMobile ?  '24px' : '16px' };
    }
    .line {
      margin: 12px 0;
      height: 1px;
      border-bottom: 1px solid #707070;
    }
    .title {
      width: 100%;
      font-size: 16px;
      font-family: Nirmala UI-Regular, Nirmala UI;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      text-align: start;
    }
    .title-x {
      width: 100%;
      font-size: 12px;
      font-family: Nirmala UI-Regular, Nirmala UI;
      font-weight: 400;
      color: #8A8A8A;
      line-height: 21px;
      text-align: start;
    }
    .content-box {
      margin-top: ${({isMobile}) => isMobile ?  '16px' : '30px' };
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 16px;
      font-family: Nirmala UI-Regular, Nirmala UI;
      font-weight: 400;
      line-height: 32px;
      .new-img {
        width: ${({isMobile}) => isMobile ?  '100%' : '100%' };
        height: ${({isMobile}) => isMobile ?  '250px' : '' };
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: ${({isMobile}) => isMobile ?  '' : '' };
        .img-n {
          width: ${({isMobile}) => isMobile ?  '210px' : '105px' };
          height: ${({isMobile}) => isMobile ?  '210px' : '105px' };
        }

      }
      .img {
        width: ${({isMobile}) => isMobile ?  '' : '100%' };
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: ${({isMobile}) => isMobile ?  '480px' : '100%' };
          height: auto;
        }
      }
      .content {
        width: 100%;
        margin-top: 16px;
        .text-box {
          display: flex;
          justify-content: space-between;
          align-items: center; 
        }
        .btn {
          margin-top: 6px;
        }
      }
    }
`
const CenterBox = styled(StyleBase)<{isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width:  ${({isMobile}) => isMobile ?  '48%' : '100%' };
  border-radius: 16px;
  overflow: hidden;
  margin-Bottom: ${({isMobile}) => isMobile ?  '' : '20px' };
`

const BigCenterBox = styled(StyleBase)<{isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width:  ${({isMobile}) => isMobile ?  '100%' : '100%' };
  border-radius: 16px;
  overflow: hidden;
  margin-Bottom: ${({isMobile}) => isMobile ?  '' : '20px' };
`


export {
  TokenText, Stake, HarvestModal, StyleContainer, Wrapper1, Card, BigCard, CenterBox,BigCenterBox
}