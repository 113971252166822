/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import {useCallback,useState} from 'react'
import styled from 'styled-components'
import ModalActions from 'components/ModalActions'
import { useHistory } from "react-router-dom";
import { Text, Button, Input, InputProps, Flex, Modal } from '@dreamweb/uikit'
import { NewModalInput } from './indexStyle'


const StyledTokenInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  background-color: #010101;
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 16px 8px 0;
  width: 100%;
`

const StyledInput = styled(Input)`
  flex: 1;
  box-shadow: none;
  margin: 0 8px;
  padding: 0 8px;
  background: rgba(255, 0, 0, 0);
  border: 1px solid #888;
  border-radius: 10px;
  :disabled {
    background-color: #000;
  }

  &:focus:not(:disabled) {
    box-shadow: 0px 0px 0px 2px #fff;
    opacity: 0.7;
  }
`

const StyledErrorMessage = styled(Text)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #fff;
  a {
    color: #fff;
  }
`

export default function Tokens({tabIndex, staked, onDismiss, deposit, withdraw, isAdd, id, matches}: any) {
  const [val, setVal] = useState('')  
  const history = useHistory()
  const [pendingTx, setPendingTx] = useState(false)
  const pegasusArr = !matches ? [
    'Unicorn',
    'Pegasus Normal',
    'Pegasus Bronze',
    'Pegasus Silver',
    'Pegasus Gold'
  ] : [
    'Unicorn NFT',
    'Pegasus Normal NFT',
    'Pegasus Bronze NFT',
    'Pegasus Silver NFT',
    'Pegasus Gold NFT'
  ]
  const MDFArr = !matches ?  [
    'MDF Normal',
    'MDF Premium'
  ] : [
    'MDF Normal NFT',
    'MDF Premium NFT'
  ]


  const handleChange = useCallback(
    (e) => {
      if (e.currentTarget.validity.valid) {
        const newVal = e.currentTarget.value.replace(/,/g, '.');
        if(newVal===0||newVal==='0') {
          if(newVal<1) {
            setVal('1');
            return
          }
        }
        setVal(newVal);
      }
    },
    [setVal],
  )

  const handleSelectMax = () => {
    setVal(staked)
  }

  
  const handleConfirmClick = async () => {
    setPendingTx(true)
    try {
      // 确认
      if(isAdd) {
        await deposit(val)
        setVal('')
      } else {
        await withdraw(val)
        setVal('')
      }
      setPendingTx(false);
      // onDismiss();
    } catch (e) {
      setPendingTx(false);
    }
  }
  return (
    <Modal style={{background: '#232323'}} title={isAdd?`Stake ${tabIndex===0?'Pegasus NFT':'MDF'}`:`Unstake ${tabIndex===0?'Pegasus NFT':'MDF'}`} onDismiss={()=>{
      onDismiss()
      // setVal('')
      }} >
      <NewModalInput>
        <div style={{ position: 'relative' }}>
          <StyledTokenInput>
            <Flex justifyContent="space-between" pl="16px">
              <Text fontSize="14px">{isAdd?'Stake':'Unstake'}</Text>
              <Text fontSize="14px">Balance: {staked}</Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mt="8px">
              <StyledInput
                disabled={pendingTx}
                pattern="^[0-9]*$"
                inputMode="decimal"
                step="any"
                min="0"
                onChange={handleChange}
                placeholder="0"
                value={val}
              />
              <div style={{display:'flex', alignItems:'center',}}>
                <Button disabled={pendingTx} style={{color: '#000'}} scale="sm" onClick={handleSelectMax} mr="8px">
                  Max
                </Button>
              <Text fontSize="16px">{tabIndex===0?pegasusArr[id]:MDFArr[id]}</Text>
              </div>
            </Flex>
          </StyledTokenInput>
          <ModalActions>
            <Button 
              style={{borderRadius: '12px'}} 
              variant="secondary" 
              onClick={()=>{
                onDismiss()
                // setVal('')
              }} 
              width="100%" 
              disabled={pendingTx}
            >
              Cancel
            </Button>
            <Button style={{borderRadius: '12px',color: '#000'}}
              width="100%"
              disabled={pendingTx||!+val||+val>staked}
              onClick={handleConfirmClick}
            >
              {pendingTx ? 'Confirming' : 'Confirm'}
            </Button>
          </ModalActions>
        </div>
      </NewModalInput>
    </Modal>
  )
}
