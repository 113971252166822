/* eslint-disable react/no-unknown-property */
/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/export */
import React, {useState,useEffect} from 'react'
import { Wrapper
} from 'views/Home/indexStyles'
import _ from 'lodash'
import getNodeUrl from 'utils/getRpcUrl'
import { useTokenBalance } from 'hooks/useTokenBalance'
import {percentileSegmentation} from 'utils/utils'
import { getBep20Contract } from 'utils/contractHelpers'
import { getUsdtAddress,
  getChangeAddress,
  getChangeDAddress,
  getChangeYAddress,
  getNftAddress,
  getFarmAddress,
  getPoolAddress,
  getLpAddress,
  getPegasusNftAddress,
  getExchangeAddress,
  getMDFNftAddress,
  getNftStakingAddress,
} from 'utils/addressHelpers'

import { ComingSoon } from 'views/Pool/indexStyle'
import { approve } from 'utils/callHelpers'
import { Decimal } from "decimal.js";
import styled from 'styled-components'
import { getPegasusNft, getMDFNft, getNftStaking, getExchange, getNDTid } from 'utils/change'
import Login from 'components/Login'
import { useHistory } from "react-router-dom";
import useWeb3 from 'hooks/useWeb3'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import useAuth from 'hooks/useAuth'
import { Contract, Provider } from 'ethers-multicall'
import useToast from 'hooks/useToast'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { formatDecimal } from 'utils/math'
import { ButtonMenu, ButtonMenuItem, ConnectorNames } from '@dreamweb/uikit'
import { Button, Pagination, Input, Modal, Select, Checkbox  } from 'antd'
import { useUpdateEffect } from 'ahooks'
import { animated } from 'react-spring/renderprops-universal'
import { StyleContainer, TabMain, NFTs, BOXs, Gallery, BuyConfirm, BoxBuyConfirm, MdfBuyConfirm } from './indexStyles'


const StyleBase = styled.div``

const Wrapper1 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:  0px;
`

const GalleryTitle = styled(StyleBase)<{isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({isMobile}) => isMobile ?  '49px' : '22px' };
  font-size: ${({isMobile}) => isMobile ?  '64px' : '32px' };
  font-family: Nirmala UI-Bold;
  font-weight: 400;
  color: #FFFFFF;
`

const horse0 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse0.mp4"
const horse1 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse1.mp4"
const horse2 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse2.mp4"
const horse3 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse3.mp4"
const horse4 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse4.mp4"

const horseArr = [horse0,horse1,horse2,horse3,horse4]


const result = sessionStorage.getItem('gas') || 50000
let tabIndexG = new RegExp('/pegasus-nft').test(window.location.href)?0:1
let gallery = 2
export default function Index() {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const web3 = useWeb3()
  const history = useHistory()
  const { account } = useWeb3React()
  const [tabIndex, setTabIndex] = useState(0)
  const usdtAddress = getUsdtAddress()
  const balance = useTokenBalance(usdtAddress);
  const [showSelect, setShowSelect] = useState<boolean>(false)
  const [showSelect1, setShowSelect1] = useState<boolean>(false)
  const [nftBuyBox, setNftBuyBox] = useState<boolean>(false)
  const [openBuy, setOpenBuy] = useState<boolean>(false)
  const [openSell, setOpenSell] = useState<boolean>(false)
  const [boxOpenBuy, setBoxOpenBuy] = useState<boolean>(false)
  const [openMdfBuy, setOpenMdfBuy] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [galleryBuyBox, setGalleryBuyBox] = useState<boolean>(false)
  const [selectType, setSelectType] = useState(history?.location?.state?.selectType || 'ALL') 
  const [selectType1, setSelectType1] = useState('ALL') 
  const [selectType2, setSelectType2] = useState(false) 
  const [ipt1, setIpt1] = useState(); 
  const [currentPage,setCurrentPage]=useState(1)
  const [total,setTotal]=useState(0)
  const [lodaing, setLodaing] = useState(false)
  const [toAddress,setToAddress] = useState()
  const [toPrice,setToPrice] = useState()
  const [levelCountArr,setLevelCountArr]=useState([])
  const [isEmpower,setIsEmpower] = useState([0,0,0])
  const [cionArr,setCionArr]=useState([])
  const [idvs,setIdvs]=useState<any>()
  const [isApproveNM, setIsApproveNM] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [isTest, setIsTest] = useState(false)
  const [isTest1, setIsTest1] = useState(false)
  const [idvsIndex, setIdvsIndex]=useState(1)
  const [levelName,setLevelName]=useState('')
  const [fee,setFee]=useState(0)
  const [iptMore, setIptMore] = useState();
  const [timeAll, setTimeAll] = useState([]);
  const [balanceArray, setBalanceArray] = useState([])
  const { login, logout } = useAuth()
  const arrPegasusName=['Unicorn',
    'Pegasus Normal',
    'Pegasus Bronze',
    'Pegasus Silver',
    'Pegasus Gold']
  const arrMDFName = ['MDF Normal',
    'MDF Premium']
  const arrPokemon = ['Pokemon']
  const [searcLevel,setSearchlevel]=useState<number>()


  const pNDTidContract = getNDTid(0)
  const mNDTidContract = getNDTid(1)

  // const pegasusNftcontract = getBep20Contract(getPegasusNftAddress(), web3)

  
  // const MDFNftNftcontract = getBep20Contract(getMDFNftAddress(), web3)
  

  const exchangeContract = getExchange(web3)


  const nftContract = getPegasusNft(web3)
  
  const MDFNftContract = getMDFNft(web3)

  
  // NFTStaking
  const NftStakingContract = getNftStaking(web3)

  const getNdtIds = async (item:any) => { 
    // const provider = new ethers.providers.JsonRpcProvider(getNodeUrl());
    // const ethcallProvider1 = new Provider(provider);
    // const arr = item?.map((i:any) => { 
    //   return (i?.res?.nftIndex==='0'?pNDTidContract:mNDTidContract).getLevel(i?.ids)
    //  })
    // await ethcallProvider1.init();
    // const view = await ethcallProvider1.all(arr)
    
    // const newItemArr = item?.map((i:any,index) => { 
    //   return {
    //     ...i,
    //     lvs: view?.[index]
    //   }
    //  })
    // return newItemArr
    
    const arr = item?.map((i:any) => { 
      return (i?.res?.nftIndex==='0'?nftContract:MDFNftContract).methods.getLevel(i?.ids).call()
     })
    const view = await Promise.all(arr)
    
    const newItemArr = item?.map((i:any,index) => { 
      return {
        ...i,
        lvs: view?.[index]
      }
     })
    return newItemArr
   }

  const getFee = async () => { 
    try {
      const getNewFee = await exchangeContract.methods.feeRate().call()
      setFee(getNewFee)
      // console.log('feeRate', getNewFee)
    } catch (error) {
      // console.log('nftserror', error)
    }
   }

  useEffect(() => { 
    getFee()
  }, [])

  const buy = async  (e, amount,cionIndex) => { 
    if(!account) {
      return
    }

    try {
      setLodaing(true)
      if(e?.res?.seller!==account) {
        // console.log(224, e?.oldIndex, e?.res?.tokenId, amount, balanceArray?.[cionIndex])
        // 判断余额是否足够
        if(amount > balanceArray?.[cionIndex]) {
          setLodaing(false)
          toastWarning('Insufficient balance')
          return
        }
        await exchangeContract.methods.buy(e?.oldIndex, e?.res?.tokenId).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
        getBalanceArray()
        toastSuccess('Buy successful')
      } else {
        await exchangeContract.methods.cancelOrder(e?.oldIndex).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
        toastSuccess('Cancel successful')
      }
      getUserTokens()
      setLodaing(false)
      setGalleryBuyBox(false)
    } catch (error) {
      setLodaing(false)
      // console.log(error)
    }
  }




  const stopPropagation = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
  }  
  const stopPropagation1 = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
  }
  const selectFunc = (e: React.MouseEvent) => {
    stopPropagation(e)
    setShowSelect(!showSelect)
  }

  const selectFunc1 = (e: React.MouseEvent) => {
    stopPropagation1(e)
    setShowSelect1(!showSelect1)
  }

    
  const handleChange = (e, set) => {
    const val = e.replace(/[^\d]/g, '');
    let num = val
    if (val === '0') {
        num = 1
    }
    set(num)
  }
  

  const changeToAddress = (e) => { 
    const val = /^[\da-z]+$/i.test(e);
    let reg = /^0x([a-fA-F0-9]){40}$/;
    if(e==='') {
      setIsTest(false)

    } else {
      setIsTest(!reg.test(e))
    }
    if(e === '') {
      setToAddress(e)
    }
    if(val) {
      setToAddress(e)
    }
  }
  const changePrice = (e) => { 
    const val = /^[\da-z]+$/i.test(e);
    let reg = /^[1-9]([0-9])$/;
    if(e==='') {
      setIsTest1(false)
    } else {
      setIsTest1(!reg.test(e))
    }
    if(e === '') {
      setToPrice(e)
    }
    if(val) {
      setToPrice(e)
    }
  }


  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);



  // 获取全部期数数据 view
  const getUserTokens = async (v?:any) => { 

    // console.log(241, v,tabIndex)
    setDataLoading(true)
    try {
      console.log("开始获取全部期数数据 view", account,500,1)
      let term1 = []  
      const res = await exchangeContract.methods.getOrders(500, 1).call()
      console.log("获取全部期数数据 view", res)
      if(!_.isEqual(timeAll,res)) {
        // console.log('getOrders', res)    
        setTimeAll(res)
        for (let index = 0; index < res?.length; index++) {
          term1.push({
            ids: res?.[index]?.tokenId,
            res: res?.[index],
            oldIndex: index
          })
        }
      } else {
        for (let index = 0; index < timeAll?.length; index++) {
          term1.push({
            ids: timeAll?.[index]?.tokenId,
            res: timeAll?.[index],
            oldIndex: index
          })
        }
      }
      const newIds = await getNdtIds(term1)
      term1 = newIds
      // console.log("当前展示页数据", {
      //   ids: [...ids].splice((currentPage-1)*8,8),
      //   lvs: [...lvs].splice((currentPage-1)*8,8)
      // })
      // 筛选自己的NFT
      if(selectType2) {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.seller===account) {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      if(selectType1==='Oldest') {
        term1 = term1.sort((a: any,b: any) => { 
          return a?.res?.startTime - b?.res?.startTime
         })
      }
      if(selectType1==='Newest') {
        term1 = term1.sort((a: any,b: any): any => { 
          return b?.res?.startTime - a?.res?.startTime
         })
      }
      if(selectType1==='Lowest Price') {
        term1 = term1.sort((a: any,b: any) => { 
          return a?.res?.price - b?.res?.price
         })
      }
      if(selectType1==='HIGHest Price') {
        term1 = term1.sort((a: any,b: any): any => { 
          return b?.res?.price - a?.res?.price
         })
      }
      if(selectType==='Unicorn') {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.nftIndex==='0' && term1?.[index]?.lvs==='0') {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      if(selectType==='Pegasus Normal') {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.nftIndex==='0'&&term1?.[index]?.lvs==='1') {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      if(selectType==='Pegasus Bronze') {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.nftIndex==='0'&&term1?.[index]?.lvs==='2') {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      if(selectType==='Pegasus Silver') {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.nftIndex==='0'&&term1?.[index]?.lvs==='3') {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      if(selectType==='Pegasus Gold') {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.nftIndex==='0'&&term1?.[index]?.lvs==='4') {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      if(selectType==='MDF Normal') {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.nftIndex==='1'&&term1?.[index]?.lvs==='0') {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      if(selectType==='MDF Premium') {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.nftIndex==='1'&&term1?.[index]?.lvs==='1') {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      if(selectType==='Pokemon') {
        const newTirm = []
        for (let index = 0; index < term1?.length; index++) {
          if(term1?.[index]?.res?.nftIndex==='2'&&term1?.[index]?.lvs==='0') {
            newTirm.push(term1?.[index])
          }
        }
        term1 = newTirm
      }
      
      setTotal(term1?.length)
      setIdvs([...term1].splice((currentPage-1)*8,8))
      setDataLoading(false)
    } catch (error) {
      // console.log(398, error)
      setDataLoading(false)
    }
    
    setDataLoading(false)
  }

  

  // 切换账号初始化
  useEffect(() => { 
    setToAddress(null)
  }, [account])


  // 用户转账NFT 总量 Do
  const transferFrom = async () => { 
    if(!account) {
      return
    }
    try {
      setLodaing(true)
      // console.log("用户转账NFT 总量 Do",tabIndex?'nftContract':'MDFNftContract',idvs?.[idvsIndex]?.ids, idvs?.[idvsIndex]?.lvs)
      await (idvs?.[idvsIndex]?.res?.nftIndex==='0'?nftContract:MDFNftContract).methods.transferFrom(account,toAddress,idvs?.[idvsIndex]?.ids).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      })
      setToAddress(null)
      setIpt1(null)
      setOpenBuy(false)
      setLodaing(false)
      // console.log('用户转账NFT 总量 Do')
      toastSuccess('Transfer successful')
      getUserTokens()
    } catch (error: any) {
      setOpenBuy(false)
      setToAddress(null)
      setLodaing(false)
      toastWarning(error?.message ? error?.message : 'Transfer failed')
    }
  }

  

  // 获取当前展示
  useEffect(() => { 
    getUserTokens()
  }, [currentPage,account,selectType, selectType1, selectType2])

  useUpdateEffect(() => { 
    setCurrentPage(1)
  }, [selectType, selectType1, selectType2])


  
  // 获取LevelName view 
  const getLevelName = async (id) => { 
    try {
      // console.log("获取LevelName view", id)
      setLevelName('')
      const newLevelName = await (idvs?.[idvsIndex]?.res?.nftIndex==='0'?nftContract:MDFNftContract).methods.getLevelName(id).call()
      // console.log('LevelName view', newLevelName)
      setLevelName(newLevelName)
    } catch (error) {
      // console.log(error)
    }
  }

  useUpdateEffect(() => { 
    // console.log(505, idvs?.[idvsIndex]?.ids)
    getLevelName(idvs?.[idvsIndex]?.lvs)
    // tokenURI(idvs?.[idvsIndex]?.ids)
  }, [idvsIndex])

  useEffect(() => { 
    tabIndexG=tabIndex
  }, [tabIndex])

  useEffect(() => { 
    changeToAddress('')
  }, [openBuy])

  useEffect(() => { 
    getCoin()
  }, [])

  
  const fetchBalance = async (tokenAddress) => {
    const contract = getBep20Contract(tokenAddress, web3)
    const res = await contract.methods.balanceOf(account).call()
    // setBalance(new BigNumber(res))
    return res
  }

  // 获取币选择列表
  const getCoin = async () => { 
    try {
      const res0 = await exchangeContract.methods.tokens(0).call()
      const res1 = await exchangeContract.methods.tokens(1).call()
      const res2 = await exchangeContract.methods.tokens(2).call()
      console.log(550, [res0, res1, res2])
      setCionArr([res0?.token, res1?.token, res2?.token])
      paycoinAllowance([res0?.token, res1?.token, res2?.token])
    } catch (error) {
      // console.log(147, error)
    } 
  }
  const getBalanceArray = async () => {
    const balanceArr = await Promise.all([...cionArr].map((item)=>{
      return fetchBalance(item)
    }))
    setBalanceArray(balanceArr)
  }

  useEffect(()=>{
    if(account) {
      getBalanceArray()
    }

  }, [cionArr,account])

  useUpdateEffect(() => { 
    paycoinAllowance(cionArr)
  }, [cionArr,account])
  
  // 查询是否授权
  const paycoinAllowance = async (addressArr) => { 
    // console.log(504, addressArr)
    if(!account) {
      return
    }
    try {
      setIsEmpower([0,0,0])
      // 查询是否授权
      const maxNum0 = await getBep20Contract(addressArr?.[0], web3).methods.allowance(account, getExchangeAddress()).call()
      const maxNum1 = await getBep20Contract(addressArr?.[1], web3).methods.allowance(account, getExchangeAddress()).call()
      const maxNum2 = await getBep20Contract(addressArr?.[2], web3).methods.allowance(account, getExchangeAddress()).call()
      // 未授权时授权
      setIsEmpower([+maxNum0,+maxNum1,+maxNum2])
    } catch (error) {
      // console.log(537, error)
    }
  }

  const empower = async (address) => { 
    // console.log('授权地址', address)
    // 授权
    try {
      setLodaing(true)
      await approve(getBep20Contract(address, web3), getExchangeAddress(), account)
      paycoinAllowance(cionArr)
      toastSuccess('Approve Success')
      setLodaing(false)
    } catch (error:any) {
      setLodaing(false)
      toastWarning(error?.message ? error?.message : 'Approve fail')
    }
  }

  return (
    <Wrapper isMobile={matches}>
      <main className='roll'>
        <div className='main'>
          <GalleryTitle isMobile={matches}>
            <div>Market Place</div>
          </GalleryTitle>
          <TabMain isMobile={matches}>
          {/* <ComingSoon>
            <div style={{marginTop: '120px'}} className="main">
              <img src="/images/ComingSoon.png" alt="" />
              <div>
                Coming Soon
              </div>
            </div>
          </ComingSoon> */}
          <Gallery isMobile={matches}>
          {
            !galleryBuyBox &&
            <div className='tab'>
              <div style={{display:'flex',alignItems:'center',marginRight:'10px', border:'1px solid rgb(255, 255, 255)', padding: '10px', marginBottom: matches ? '' : '15px'}}><Checkbox value={selectType2} onChange={(e) => {
                setSelectType2(e.target.checked)
              }}>My Selling NFT</Checkbox></div>
              <div style={{border: '1px solid #FFFFFF', marginBottom: matches ? '' : '15px'}}  className="select-box mr-16 relative flex justify-start items-center cursor-pointer px-4 h-8 border border-line rounded-90" onClick={(e) => selectFunc1(e)}>
                <span className="w-26 text-text2 text-4xl">{selectType1}</span>
                <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/M3.png" alt="" style={{marginLeft:'5px',width: '12px', height: '6.46px',}}/>
                {
                  showSelect1 && 
                  <div className="flex flex-col justify-around items-start absolute left-0 top-8 bg-black2 py-4 px-5 text-4xl text-text1 rounded-8 z-10 w-full">
                          <span style={{display:'block',width:'100%'}} className="hover:text-text2 cursor-pointer" onClick={() => {
                            setSelectType1('ALL') 
                            setGalleryBuyBox(false)}}>ALL</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType1('Newest')   
                            setGalleryBuyBox(false)}}>Newest</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType1('Oldest')   
                            setGalleryBuyBox(false)}}>Oldest</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType1('Lowest Price')   
                            setGalleryBuyBox(false)}}>Lowest Price</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType1('HIGHest Price')   
                            setGalleryBuyBox(false)}}>Highest Price</span>
                        </div>
                }
              </div>
              <div style={{border: '1px solid #FFFFFF', marginBottom: matches ? '' : '15px'}}  className="select-box mr-16 relative flex justify-start items-center cursor-pointer px-4 h-8 border border-line rounded-90" onClick={(e) => selectFunc(e)}>
                <span className="w-26 text-text2 text-4xl">{selectType}</span>
                <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/M3.png" alt="" style={{marginLeft:'5px',width: '12px', height: '6.46px',}}/>
                {
                  showSelect && 
                  <div className="flex flex-col justify-around items-start absolute left-0 top-8 bg-black2 py-4 px-5 text-4xl text-text1 rounded-8 z-10 w-full">
                          <span style={{display:'block',width:'100%'}} className="hover:text-text2 cursor-pointer" onClick={() => {
                            setSelectType('ALL') 
                            setSearchlevel(9)
                            setGalleryBuyBox(false)}}>ALL</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType('Unicorn')   
                            setSearchlevel(0)
                            setGalleryBuyBox(false)}}>Unicorn</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType('Pegasus Normal')   
                            setSearchlevel(1)
                            setGalleryBuyBox(false)}}>Pegasus Normal</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType('Pegasus Bronze')  
                            setSearchlevel(2) 
                            setGalleryBuyBox(false)}}>Pegasus Bronze</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType('Pegasus Silver')   
                            setSearchlevel(3)
                            setGalleryBuyBox(false)}}>Pegasus Silver</span>
                          <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                            setSelectType('Pegasus Gold')  
                            setSearchlevel(4) 
                            setGalleryBuyBox(false)}}>Pegasus Gold</span>
                            <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                              setSelectType('MDF Normal')   
                              setSearchlevel(4)
                              setGalleryBuyBox(false)}}>MDF Normal</span>
                            <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                              setSelectType('MDF Premium')   
                              setSearchlevel(5)
                              setGalleryBuyBox(false)}}>MDF Premium</span>
                              <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                setSelectType('Pokemon')   
                                setSearchlevel(6)
                                setGalleryBuyBox(false)}}>Pokemon</span>
                        </div>
                }
              </div>
            </div>
          }
          <>
          {
            !galleryBuyBox ? 
            <>
            {
              idvs?.length ? 
              <>
                <div className='content'>
                  {
                    idvs?.map((item,i) => { 
                      return <div key={`${item?.ids}${i}`} className='img'>
                        
                        {
                          idvs?.[i]?.res?.nftIndex==='0' && sessionStorage.getItem('navigatorName') === '-1' ? 
                          <video 
                            className='videom'
                            x5-playsinline 
                            webkit-playsinline 
                            x5-video-player-type="h5" 
                            x5-video-player-fullscreen
                            x5-video-orientation="portraint" 
                            playsInline 
                            onClick={()=>{
                            setGalleryBuyBox(true)
                            setIdvsIndex(i)
                          }} loop autoPlay muted controls={false}>
                              <source src={horseArr?.[idvs?.[i].lvs]}/>
                          </video> : ''
                        }                        {
                          idvs?.[i]?.res?.nftIndex==='0' && sessionStorage.getItem('navigatorName') !== '-1' ? 
                          
                          <img onClick={()=>{
                            setGalleryBuyBox(true)
                            setIdvsIndex(i)
                        }} src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${idvs?.[i]?.res?.nftIndex==='0'? idvs?.[i]?.lvs : idvs?.[i]?.lvs===0?5:6}.png`}  alt="" /> : ''
                        }
                        {
                          idvs?.[i]?.res?.nftIndex==='1' ?
                            <img onClick={()=>{
                              setGalleryBuyBox(true)
                              setIdvsIndex(i)
                          }} src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${idvs?.[i]?.res?.nftIndex==='0'? idvs?.[i]?.lvs : idvs?.[i]?.lvs===0?5:6}.png`}  alt="" /> : ''
                        }
                        {
                          idvs?.[i]?.res?.nftIndex==='2' ?
                            <img onClick={()=>{
                              setGalleryBuyBox(true)
                              setIdvsIndex(i)
                          }} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/pokemon/images/Pokemon.JPG"  alt="" /> : ''
                        }
                        {
                          !account ? 
                          <Login
                              setIsModalOpen={setIsModalOpen}
                              isModalOpen={isModalOpen}
                          >
                            <div style={{width: '170px',height: '32px', border: '1px solid #fff', color: '#fff' }} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
                          </Login> : 
                          <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <div style={{width:matches?'282px':'100%',marginTop:15,padding:matches?'':'0 10px', height: '20.8px'}} className='flex justify-center items-center'>
                              
                              {
                                idvs?.[i]?.res?.seller!==account && 
                                <>
                                <div>{new Decimal(idvs?.[i]?.res?.price).div(1e18).toFixed(4)} &nbsp;
                                {
                                  idvs?.[i]?.res?.tokenIndex === '0' && 'USDT'
                                }
                                {
                                  idvs?.[i]?.res?.tokenIndex === '1' && 'DCOIN'
                                }
                                {
                                  idvs?.[i]?.res?.tokenIndex === '2' && 'KYURYO'
                                }
                                </div>
                                </>
                              }
                            </div>
                            <Button loading={lodaing} onClick={()=>{
                                if(idvs?.[i]?.res?.seller===account) {
                                  buy(idvs?.[i], idvs?.[i]?.res?.price, idvs?.[i]?.res?.tokenIndex)
                                  return
                                }
                                if(+isEmpower[idvs?.[i]?.res?.tokenIndex]) {
                                  buy(idvs?.[i], idvs?.[i]?.res?.price, idvs?.[i]?.res?.tokenIndex)
                                } else {
                                  empower(cionArr?.[idvs?.[i]?.res?.tokenIndex])
                                }
                              }} style={{marginTop: '12px',color:'#fff',border: '1px solid #fff', fontFamily: 'Nirmala UI-Regular'}}>
                                <>
                                    {idvs?.[i]?.res?.seller===account?'Cancel':
                                      +isEmpower[idvs?.[i]?.res?.tokenIndex] ? 
                                    'Buy' : 
                                    <>Approve</>
                                    }
                                  </>
                              </Button>
                          </div>
                        }
                      </div>
                      })
                  }
                </div>
                {
                  total > 8 &&
                  <div className='page'>
                    <Pagination onChange={(e)=>{
                      setCurrentPage(e)
                    }} current={currentPage} total={total} pageSize={8} showSizeChanger={false}/>
                  </div>
                }
              </> : 
              <div className='no-Data'>{dataLoading?'loading':'No Data'}</div>
            }
            </> : 
            <div className='buy-box'>
              <div onClick={()=>{
                  setGalleryBuyBox(false)
              }} style={{display:'flex',alignItems:'center',position:'absolute',top:'24px',left:matches?'24px':'15px'}}>
                <img style={{width:24,marginRight:5}} src="/images/change/back.png" alt="" />
                <div>Go back</div>
              </div>
              <div className='img'>
                {
                  idvs?.[idvsIndex]?.res?.nftIndex==='0' && sessionStorage.getItem('navigatorName') === '-1' ? 
                  <video 
                    x5-playsinline 
                    webkit-playsinline 
                    x5-video-player-type="h5" 
                    x5-video-player-fullscreen
                    x5-video-orientation="portraint" 
                    playsInline  loop autoPlay muted controls={false}>
                      <source src={horseArr?.[idvs?.[idvsIndex]?.lvs]}/>
                  </video> :
                  ''
                }
                {
                  idvs?.[idvsIndex]?.res?.nftIndex==='0' && sessionStorage.getItem('navigatorName') !== '-1' ? 
                  <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${idvs?.[idvsIndex]?.res?.nftIndex==='0'? idvs?.[idvsIndex]?.lvs : idvs?.[idvsIndex]?.lvs===0?5:6}.png`} alt="" /> :
                  ''
                }
                {
                  idvs?.[idvsIndex]?.res?.nftIndex==='1' ? 
                  <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${idvs?.[idvsIndex]?.res?.nftIndex==='0'? idvs?.[idvsIndex]?.lvs : idvs?.[idvsIndex]?.lvs===0?5:6}.png`} alt="" /> : ''
                }
                {
                  idvs?.[idvsIndex]?.res?.nftIndex==='2' ? 
                  <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/pokemon/images/Pokemon.JPG" alt="" /> : ''
                }
              </div>
              <div style={{width:matches?'480px':'100%',marginTop:15,padding:matches?'':'0 10px'}} className='flex justify-center items-center'>
                <div>Name:</div>
                &nbsp;
                <div>
                {idvs?.[idvsIndex]?.res?.nftIndex==='0'?arrPegasusName?.[idvs?.[idvsIndex]?.lvs]:''}
                {idvs?.[idvsIndex]?.res?.nftIndex==='1'?arrMDFName?.[idvs?.[idvsIndex]?.lvs]:''}
                {idvs?.[idvsIndex]?.res?.nftIndex==='2'?arrPokemon?.[idvs?.[idvsIndex]?.lvs]:''}
                </div>
              </div>
              <div style={{width:matches?'480px':'100%',marginTop:15,padding:matches?'':'0 10px'}} className='flex justify-center items-center'>
                <div>NFT ID:</div>&nbsp;
                <div>{idvs?.[idvsIndex]?.ids} 
                </div>
              </div>
              <div style={{width:matches?'480px':'100%',marginTop:15,padding:matches?'':'0 10px'}} className='flex justify-center items-center'>
                <div>Price:</div>&nbsp;
                <div>{new Decimal(idvs?.[idvsIndex]?.res?.price).div(1e18).toFixed(4)} &nbsp;
                {
                  idvs?.[idvsIndex]?.res?.tokenIndex === '0' && 'USDT'
                }
                {
                  idvs?.[idvsIndex]?.res?.tokenIndex === '1' && 'DCOIN'
                }
                {
                  idvs?.[idvsIndex]?.res?.tokenIndex === '2' && 'KYURYO'
                }
                </div>
              </div>
              <div className='btn'>
                {
                  !account ? 
                  <Login
                      setIsModalOpen={setIsModalOpen}
                      isModalOpen={isModalOpen}
                  >
                    <div style={{width: '170px',height: '56px', border: '1px solid #fff', color: '#fff' }} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
                  </Login> : 
                  <Button loading={lodaing} onClick={()=>{
                    if(idvs?.[idvsIndex]?.res?.seller===account) {
                      buy(idvs?.[idvsIndex], idvs?.[idvsIndex]?.res?.price, idvs?.[idvsIndex]?.res?.tokenIndex)
                      return
                    }
                    if(+isEmpower[idvs?.[idvsIndex]?.res?.tokenIndex]) {
                      buy(idvs?.[idvsIndex], idvs?.[idvsIndex]?.res?.price, idvs?.[idvsIndex]?.res?.tokenIndex)
                    } else {
                      empower(cionArr?.[idvs?.[idvsIndex]?.res?.tokenIndex])
                    }
                  }}>
                    <>
                        {idvs?.[idvsIndex]?.res?.seller===account?'Cancel':
                        +isEmpower[idvs?.[idvsIndex]?.res?.tokenIndex] ?
                        'Buy' : 
                        <>Approve</>
                        }
                      </>
                  </Button>
                }
              </div>
            </div>
          }
          </>
            </Gallery>
          </TabMain>
        </div>
      </main>
      
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openBuy} closable={false}>
          <BuyConfirm isMobile={matches}>
            <div className='title'>{idvs?.[idvsIndex]?.res?.nftIndex==='0'?'TRANSFER':'TRANSFER'}</div>
            <div className='amount-box'>
              <div>Name:</div>
              <div>
                {idvs?.[idvsIndex]?.res?.nftIndex==='0'?arrPegasusName?.[idvs?.[idvsIndex]?.lvs]:''}
                {idvs?.[idvsIndex]?.res?.nftIndex==='1'?arrMDFName?.[idvs?.[idvsIndex]?.lvs]:''}
                {idvs?.[idvsIndex]?.res?.nftIndex==='2'?arrPokemon?.[idvs?.[idvsIndex]?.lvs]:''}
              </div>
            </div>
            <div className='amount-box'>
              <div>Address:</div>
              <div style={matches?{width:'270px',position:'relative'}:{position:'relative'}}>
                <Input 
                  value={toAddress} 
                  onChange={e => {
                        changeToAddress(e.target.value)
                      }}
                      />
                {
                  isTest &&
                  <div style={{position:'absolute', left: 0, bottom: '-28px',fontSize:'12px',color:'red'}}>invalid address</div>
                }
                </div>
            </div>
            {/* <div className='nft-box'>
              <div>Total price:</div><div>{percentileSegmentation(ipt1 && 100 * ipt1)} USDT</div>
            </div> */}
            <div className='btn btn-change'>
              <Button style={{border:'none'}} loading={lodaing} className='Cancle'  onClick={()=>{setOpenBuy(false)}}>Cancel</Button>
              <Button disabled={!toAddress} style={{border:'none', color: '#000', opacity: !toAddress ? 0.8 : 1}} loading={lodaing} onClick={()=>{
                transferFrom()
                }}>Confirm</Button>
            </div>
          </BuyConfirm>
      </Modal>      
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openSell} closable={false}>
          <BuyConfirm isMobile={matches}>
            <div className='title'>{idvs?.[idvsIndex]?.res?.nftIndex==='0'?'TRANSFER':'TRANSFER'}</div>
            <div className='amount-box'>
              <div>Name:</div>
              <div>
                {idvs?.[idvsIndex]?.res?.nftIndex==='0'?arrPegasusName?.[idvs?.[idvsIndex]?.lvs]:''}
                {idvs?.[idvsIndex]?.res?.nftIndex==='1'?arrMDFName?.[idvs?.[idvsIndex]?.lvs]:''}
                {idvs?.[idvsIndex]?.res?.nftIndex==='2'?arrPokemon?.[idvs?.[idvsIndex]?.lvs]:''}
              </div>
            </div>
            <div className='amount-box'>
              <div>Name:</div>
              <div  style={matches?{width:'270px',position:'relative'}:{position:'relative'}}>
              <Select
                style={{ width: '100%' }}
                optionFilterProp="children"
                onChange={()=>{
                  // 
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[
                  {
                    value: 'jack',
                    label: 'Jack',
                  },
                  {
                    value: 'lucy',
                    label: 'Lucy',
                  },
                  {
                    value: 'tom',
                    label: 'Tom',
                  },
                ]}
              /></div>
            </div>
            <div className='amount-box'>
              <div>Price:</div>
              <div style={matches?{width:'270px',position:'relative'}:{position:'relative'}}>
                <Input 
                  value={toPrice} 
                  onChange={e => {
                      changePrice(e.target.value)
                      }}
                      />
                {
                  isTest &&
                  <div style={{position:'absolute', left: 0, bottom: '-28px',fontSize:'12px',color:'red'}}>invalid Price</div>
                }
                </div>
            </div>
            {/* <div className='nft-box'>
              <div>Total price:</div><div>{percentileSegmentation(ipt1 && 100 * ipt1)} USDT</div>
            </div> */}
            <div className='btn btn-change'>
              <Button style={{border:'none'}} loading={lodaing} className='Cancle'  onClick={()=>{setOpenSell(false)}}>Cancel</Button>
              <Button disabled={!toAddress} style={{border:'none', color: '#000', opacity: !toAddress ? 0.8 : 1}} loading={lodaing} onClick={()=>{
                // 
                }}>Confirm</Button>
            </div>
          </BuyConfirm>
      </Modal>
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openMdfBuy} closable={false}>
          <MdfBuyConfirm isMobile={matches}>
            <div className='title'>Buy Confirm</div>
            <div className='nft-box'>
              <div>Number:</div><div><Input value={iptMore} onChange={e => handleChange(e.target.value, setIptMore)}/></div>
            </div>
            <div className='amount-box'>
              <div>Amount:</div><div>100 USDT</div>
            </div>
            <div className='btn btn-change'>
              <Button className='Cancle'  onClick={()=>{setOpenMdfBuy(false)}}>Cancel</Button>
              <Button  disabled={!iptMore}  style={!iptMore?{background: '#6d6d6d', border: 'none'}:{}} onClick={()=>{
                setIptMore(null)
                setOpenMdfBuy(false)
                }}>Confirm</Button>
            </div>
          </MdfBuyConfirm>
      </Modal>
      
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={boxOpenBuy} closable={false}>
          <BoxBuyConfirm isMobile={matches}>
            <div className='title'>Buy One MDF</div>
            <div className='btn btn-change'>
              <Button className='Cancle'  onClick={()=>{setBoxOpenBuy(false)}}>Cancel</Button>
              <Button onClick={()=>{
                setBoxOpenBuy(false)
                }}>Confirm</Button>
            </div>
          </BoxBuyConfirm>
      </Modal>
    </Wrapper>
  )
}
