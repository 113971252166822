/* eslint-disable import/prefer-default-export */
import { BIG_TEN } from 'utils/bigNumber'
import BigNumber from 'bignumber.js/bignumber'


export const BASE_EXCHANGE_URL = ''
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const YEAR_SECOND = new BigNumber(60 * 60 * 24 * 365)


export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`


export const BASE_BSC_SCAN_URL = 'https://testnet.bscscan.com'