/* eslint-disable import/no-cycle */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import qs from 'qs';
// import cookies from 'cookies-js';
/**
 * 生成基础axios对象，并对请求和响应做处理
 * 前后端约定接口返回解构规范
 * {
 *    code:200,
 *    data:"成功",
 *    message:""
 * }
 */
import axios from 'axios';
// import { Message } from 'element-ui';
// import store from '@/store';

// 创建一个独立的axios实例
const service = axios.create({
  // 设置baseUrl地址,如果通过proxy跨域可直接填写base地址
  // baseURL: 'http://8.210.35.65:8188',
  // 定义统一的请求头部
  headers: {
    // Form Data 格式
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    // language: '555',
  },
  // 配置请求超时时间
  timeout: 60000,
  // 如果用的JSONP，可以配置此参数带上cookie凭证，如果是代理和CORS不用设置
  // withCredentials: true,
});
// 请求拦截
service.interceptors.request.use((config) => {
  // console.log('请求拦截: ', config);
  // config.headers['Accept-Language'] = cookies.get('language') || 'en-US';
  // config.headers.Language = cookies.get('language') || 'en-US';
  // config.headers.Token = cookies.get('token');
  // if (+store?.state.windowWidth >= 1040) {
  //   config.headers.Source = 'PC';
  // } else {
  //   config.headers.Source = 'Web';
  // }
  // 只针对get方式进行序列化
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'indices', allowDots: true });
    };
  }
  return config;
});

// 返回拦截
service.interceptors.response.use((response) => {
  // 获取接口返回结果
  const res = response.data;
  if (res.code === 200) {
    return res;
  } if ([1006, 1007, 10021, 10005, 10011].includes(res.code)) { // 去登录，错误提示、异常抛出由后续流程继续处理
    // Message.error(res.msg);
    // cookies.expire('token'); // 使凭证过期
    // store.commit('setLoginSuccessful');
    // 也可使用router进行跳转
    window.location.href = '/#/';
    return null;
  }
  // 错误显示可在service中控制，因为某些场景我们不想要展示错误
  // Message.error(res.msg);
  return res;
}, () => {
  // Message.error('Network request exception, please try again later!');
});
export default service;
