/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-cycle */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Login from 'components/Login'
import useWeb3 from 'hooks/useWeb3'
import { getDcoinAddress , 
  getFarmAddress,
  getPoolAddress
} from 'utils/addressHelpers'
import { accMinus, formatDecimal } from 'utils/math'
import  { Button, Modal } from 'antd'
import { useHistory } from "react-router-dom";
import { getBep20Contract } from 'utils/contractHelpers'
import { percentileSegmentation } from 'utils/utils'
import { Decimal } from "decimal.js";
import _ from 'lodash'
import { useWeb3React } from '@web3-react/core'
import { border } from "styled-system";
import Tokens from './Tokens'
import { WallentStore } from "../../../store/wallet.store";

interface CardProps {
  key: any,
  [key: string]: any
}

const Card = (props: any) => {

  const { userInfo, lpContract, getPending, deposit, approve, claim, withdraw, itemData, menuData, program, showDetailClick } = props
  const web3 = useWeb3()
  const history = useHistory()
  const {token: {tokenArr,addArr}, isFarms, idx} = itemData
  const [data, setData] = useState(itemData)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false)
  const [isAdd,setIsAdd] = useState(false)
  const [isPledge, setIsPledge] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isHarvestLoading,setIsHarvestLoading]=useState(false)
  const [earnedNum, setEarnedNum] = useState(0)
  // const [tokenArr, setTokenArr]=useState<any>([])
  const [staked, setStaked]=useState(0)
  // const [addArr, setAddArr]=useState<any>([])
  const [timer,setTimer]=useState(null)
  const [isStackToken, setIsStackToken] = useState(false)
  const [isStackToken1, setIsStackToken1] = useState(false)
  const [showListDetial, setshowListDetial] = useState<boolean>(data.showDetail)
  const { account } = useWeb3React()

  
  useEffect(() => {
    setData(itemData)
    paycoinAllowance(itemData?.paycoin)
    if(account) {
      getUserInfo()
    }
  }, [itemData, account])
  

  const getUserInfo = async () => {
    const stake1 = await userInfo(idx)
    setStaked(new Decimal(stake1?.staked).div(1e18).toNumber())
  }

  // 查询是否授权
  const paycoinAllowance = async (address) => { 
    if(!account) {
      return
    }
    // 查询是否授权
    const maxNum = await getBep20Contract(address, web3).methods.allowance(account, data?.farmAddress).call()
    
    // 未授权时授权
    if(!+maxNum){
      setIsApprove(false)
    } else {
      setIsApprove(true)
    }
  }

  // 获取token0 - token1
  // const getToken = async () => { 
  //   if(isFarms) {
  //     console.log(2)
  //     console.log('获取token0 - token1', data?.paycoin)
  //     const res = await lpContract.methods.getReverseTokens(data?.paycoin).call()
  //     getIconName(res)
  //     console.log('token0 - token1', res)
  //   } else {
  //     console.log(3, isFarms)
  //     getIconName(null)
  //   }
  // }

  
  
  // getIconName
  // const getIconName = async (e) => { 
  //   const paycoinContract = await getBep20Contract(data?.paycoin)
  //   const getPaycoinToken1 = await paycoinContract.methods.symbol().call()
  //   console.log(67, getPaycoinToken1)
  //   if(e) {
  //     const token0Contract = await getBep20Contract(e?.[0])
  //     const token1Contract = await getBep20Contract(e?.[1])
  //     const getToken0 = await token0Contract.methods.symbol().call()
  //     const getToken1 = await token1Contract.methods.symbol().call()
  //     console.log(idx, 'token0 token1', data?.paycoin, getPaycoinToken1, getToken0, getToken1)
  //     setTokenArr([getPaycoinToken1, getToken0, getToken1])
  //     setAddArr([data?.paycoin, e?.[0], e?.[1]])
  //   } else {
  //     setTokenArr([getPaycoinToken1])
  //     setAddArr([data?.paycoin])
  //   }
  // }
  useEffect(() => { 
    // if(!data?.paycoin) {
    //   return
    // }
    // getToken()
  }, [data?.paycoin])

  useEffect(() => {
    const fn = async () => {
      const num = await getPending(idx)
      setEarnedNum(num)
      setIsPledge(!!+num)
    }

    fn()
    clearInterval(timer)
    const newTimer = setInterval(() => fn(), 10000)
    setTimer(newTimer)
    return () => clearInterval(timer)
  }, [])

  return (
    <div className="md:w-96 w-full mx-auto">
      <div className="card px-4 lg:px-5 py-8 bg-black2 lg:rounded-20 rounded-12 relative ">

        {/* {showRequiredModel && (<RequiredModel show={showRequiredModel} baseTokenSymbol={data.baseTokenSymbol} onChangeShow={setRequiredModel} />)} */}

        {/* {showStakeInPoolModel && (<StakeInPool baseTokenSymbol={data.baseTokenSymbol} balace={data.userInfo.baseBalance} show={showStakeInPoolModel} onChangeShow={setShowStakeInPoolModel} onConfimValue={confirmDeposit} data={data} />)}
        {showUnStakeModel && (<Unstake baseTokenSymbol={data.baseTokenSymbol} balace={data.userInfo.amount} show={showUnStakeModel} onChangeShow={setShowUnStakeModel} onConfimValue={confirmWithdraw} data={data} />)} */}


        <div className="flex justify-start items-center">
          <div className="relative">
              <img  src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${isFarms?tokenArr?.[1]||'USDT':tokenArr?.[0]||'DCOIN'}.png`} alt="" className="" style={{width: '45px'}}/>
            <div className="absolute -right-2 -bottom-2 w-6 h-6 rounded-c bg-btn">
              <img  src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${isFarms?tokenArr?.[2]||'DCOIN':'DCOIN'}.png`} alt="" />
            </div>
          </div>
          <div className="flex flex-col ml-6">
            {
              isFarms ?
              <span className="text-6xl text-text2">{tokenArr?.[1]}/{tokenArr?.[2]} LP</span>:
              <span className="text-6xl text-text2">Stake  {tokenArr?.[0]}</span>
            }
          </div>
        </div>
        <div className="border rounded-8 p-4 mt-4">
          <div className="flex justify-between items-center ">
            <span className="text-4xl">APY:</span>
            <span className="text-4xl text-text2">{data?.apy/100}%</span>
          </div>
          <div className="flex justify-between items-center mt-3">
            <span className="text-4xl">Earn:</span>
            <span className="text-4xl text-text2">DCOIN</span>
          </div>
          {/* <div className="flex justify-between items-center mt-3">
            <span className="text-4xl">Total Staked:</span>
            <span className="text-4xl text-text2">{new Decimal(data?.totalStaked||0).div(1e18).toFixed() || 0}</span>
          </div>
          <div className="flex justify-between items-center mt-3">
            <span className="text-4xl">Start Time:</span>
            <span className="text-4xl text-text2">08:11:32</span>
          </div> */}
        </div>

        <div style={{border: '2px solid',padding: '14px',borderRadius: '8px',}} className="mt-4">
          <span className="text-text2 text-4xl">DCOIN EARNED</span>
          <div className="flex justify-between items-center mt-2">
            <div className="flex flex-col text-4xl">
              <span className="text-text2 text-8xl">{+earnedNum !== 0 ? formatDecimal(new Decimal(earnedNum).div(1e18).toFixed(9), 8)||0 : '0.00000000'}</span>
              {/* <span>{`~ ${percentileSegmentation(earnedNum, 3) || 0} USDC`}</span> */}
            </div>
            <Button 
              disabled={!+earnedNum}  
              loading={isHarvestLoading}
              onClick={async ()=>{
                try {
                  setIsHarvestLoading(true)
                  await claim(idx)
                  setIsHarvestLoading(false)
                } catch (error) {
                  setIsHarvestLoading(false)
                }
                setIsHarvestLoading(false)
              }} 
              style={{width: '75px',height: '26px', background: isLoading||!+earnedNum? '#6D6D6D':'#fff', color: isLoading||!+earnedNum? '#B9B9B9':'#000', borderRadius: '4px',border:'none'}} className="flex justify-center items-center w-28 h-12 rounded-12">Harvest</Button>
          </div>
        </div>
        <div style={{border: '2px solid',padding: '14px',borderRadius: '8px',marginTop:'12px'}}> 
          <div  className="flex justify-between items-center mt-3">
            {
              isFarms ? (
                <span className="text-4xl text-text2">LP STAKED</span>
              ) : (
                <span className="text-4xl text-text2">{tokenArr?.[0]} STAKED</span>
              )
            }
          </div>
          {
            /** *
             *  1. 未授权，仅展示授权按钮，置灰
              * 2. 授权但未质押，仅展示stake按钮，置灰
              * 3. 存在质押，展示质押数和unstake按钮，点击执行赎回（全部），执行完后无质押，置灰按钮
            */
          }
          {
            !account ? (
              <Login
                  style={{width: '100%'}}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
              >
                <div style={{width: '340px',height: '56px',background: 'rgb(255, 255, 255)', marginTop: '10px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
              </Login>
            ) : (
              <div>
                {
                  account && !isApprove ? (
                    <Button loading={isLoading} onClick={async ()=> {
                      try {
                        setIsLoading(true)
                        await approve(data.paycoin)
                        setIsLoading(false)
                      } catch (error) {
                        setIsLoading(false)
                      }
                      paycoinAllowance(itemData?.paycoin)
                    }} style={{background:'#fff',borderRadius:'12px', color: '#000'}} className="flex items-center justify-center w-full h-10 mt-3 text-text3 text-6xl  rounded-90">Enable</Button>
                  ) : (
                    <div>
                      {/* 是否有质押 */}
                      {
                        isPledge ? (
                          <div>
                            <div className="border border-line rounded-12 py-4 flex flex-col items-start justify-around w-full mr-8">
                              <div className="flex justify-between items-center w-full">
                                <div className="flex flex-col w-full">
                                  <span className="text-text2 text-10xl">{formatDecimal(new Decimal(staked).toFixed(), 4)}</span>
                                  {/* <span className="text-¬text1 text-4xl">~ xxx USDC</span> */}
                                </div>
                                <div className="flex justify-center items-center">
                                  {/* finished 状态不可点击 */}
                                  <Button  onClick={()=>{
                                    setIsAdd(false)
                                    setIsStackToken1(true)
                                  }} style={{border: '1px solid #fff', color: '#fff'}} className="flex justify-center items-center w-12 h-12 mr-4 rounded-12  border text-text2">-</Button>
                                  <Button  onClick={()=>{
                                    setIsAdd(true)
                                    setIsStackToken(true)
                                  }} style={{border: '1px solid #fff', color: '#fff'}} className="flex justify-center items-center w-12 h-12 rounded-12 border text-text2">+</Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          // finished 状态 无质押变灰不可点击
                          <Button style={{borderRadius:'12px',color:'#000'}} onClick={()=>{
                            setIsAdd(true)
                            setIsStackToken(true)
                          }} className="flex justify-center items-center w-full h-10 mt-3 text-6xl  rounded-90 bg-btn" >Stake</Button>
                        )
                      }
                    </div>
                  )
                }
              </div>
            )
          }
        </div>

        <div className="noselect w-full flex justify-center items-center mt-4 lg:cursor-pointer" onClick={() => { setshowListDetial(!showListDetial)
            }}>
          {
            showListDetial ? (
              <div className="flex justify-center items-center">
                <span className="text-text2 text-4xl font-medium mr-2">Hide</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#CDCDCD">
                  <g transform="translate(6 3.667) rotate(180)">
                    <path d="M3.471,5.8a.667.667,0,0,0,0-.943L1.609,3,3.471,1.138A.667.667,0,0,0,2.529.2L.2,2.529a.667.667,0,0,0,0,.943L2.529,5.8A.667.667,0,0,0,3.471,5.8Z" transform="translate(0 3.667) rotate(-90)" />
                  </g>
                </svg>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <span className="text-text4 text-4xl font-medium mr-2">Details</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#CDCDCD">
                  <g transform="translate(0 0)">
                    <path d="M3.471,5.8a.667.667,0,0,0,0-.943L1.609,3,3.471,1.138A.667.667,0,0,0,2.529.2L.2,2.529a.667.667,0,0,0,0,.943L2.529,5.8A.667.667,0,0,0,3.471,5.8Z" transform="translate(0 3.667) rotate(-90)" />
                  </g>
                </svg>
              </div>
            )
          }
        </div>

        <div className={`${showListDetial ? 'h-auto' : 'h-0 overflow-hidden'}`}>
          <div style={{backgroundColor: '#222'}} className="rounded-8 p-4 bg-black4 mt-4">
            <div className="flex justify-between items-center mt-3">
              <span className="text-4xl text-text2">Total Staked:</span>
              <span className="text-4xl text-text2">{new Decimal(data?.totalStaked||0).div(1e18).toFixed() || 0}  {isFarms?'LP':tokenArr?.[0]}</span>
            </div>
            <div className="flex justify-between items-center text-4xl">
              <span className="text-4xl text-text2">Start Time:</span>
              <span className="text-4xl text-text2">08:11:32</span>
            </div>
            <div onClick={()=> {
              window.open(`https://testnet.bscscan.com/token/${addArr?.[0]}`)
            }}  style={{textAlign: 'center'}} className="hover-link border border-btn rounded-20 text-2xl py-1 text-text2">See Token Info</div>
            {
              isFarms && 
              <div  style={{textAlign: 'center'}} onClick={()=>history.push(`/add/${addArr?.[1]}/${addArr?.[2]}`)}  className="hover-link border border-btn rounded-20 text-2xl py-1 text-text2" >Add {tokenArr?.[1]}/{tokenArr?.[2]} LP</div>
            }
            <div onClick={()=> {
              window.open(`https://testnet.bscscan.com/address/${isFarms?getFarmAddress():getPoolAddress()}`)
            }}  style={{textAlign: 'center'}} className="hover-link border border-btn rounded-20 text-2xl py-1 text-text2">View Contract</div>
          </div>
        </div>

      </div>
      
        
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken} closable={false}>
        <Tokens isFarms={isFarms} link={`/add/${addArr?.[1]}/${addArr?.[2]}`} name={`${tokenArr?.[1]}/${tokenArr?.[2]}`} staked={staked} lpContract={lpContract} tokenArr={tokenArr} idx={idx} isAdd={isAdd} address={data?.paycoin} onDismiss={()=>setIsStackToken(false)} 
          deposit={async (e,v)=>{
            await deposit(e,v)
            getUserInfo()
          }}
          withdraw={async (e,v)=>{
            await withdraw(e,v)
            getUserInfo()
          }}/>
      </Modal>
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken1} closable={false}>
        <Tokens isFarms={isFarms} link={`/add/${addArr?.[1]}/${addArr?.[2]}`} name={`${tokenArr?.[1]}/${tokenArr?.[2]}`} staked={staked} lpContract={lpContract} tokenArr={tokenArr} idx={idx} isAdd={isAdd} address={data?.paycoin} onDismiss={()=>setIsStackToken1(false)} 
          deposit={async (e,v)=>{
            await deposit(e,v)
            getUserInfo()
          }}
          withdraw={async (e,v)=>{
            await withdraw(e,v)
            getUserInfo()
          }}/>
      </Modal>
    </div>
  )
}
export default observer(Card)