/* eslint-disable consistent-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/export */
import React, {useState,useEffect} from 'react'
import { Wrapper
, ShowMenu } from 'views/Home/indexStyles'
import { useTokenBalance } from 'hooks/useTokenBalance'
import {percentileSegmentation, getNFTs, getUserLevelTokens, getNFTsNoSearch, getNFTsStakingForSynchronous } from 'utils/utils'
import { getUsdtAddress ,
  getChangeAddress,
  getChangeDAddress,
  getChangeYAddress,
  getNftAddress,
  getFarmAddress,
  getPoolAddress,
  getLpAddress,
  getPegasusNftAddress,
  getExchangeAddress,
  getMDFNftAddress,
  getNftStakingAddress,
  getPokemonNftAddress,
} from 'utils/addressHelpers'
import { formatDecimal, big1e18 } from 'utils/math'
import styled from 'styled-components'
import { getPegasusNft, getMDFNft, getNftStaking, getExchange, getPokemonNft, getMarketNft, getMarketMDF, getSell } from 'utils/change'
import Login from 'components/Login'
import { useHistory } from "react-router-dom";
import { ComingSoon } from 'views/Pool/indexStyle'
import useWeb3 from 'hooks/useWeb3'
import BigNumber from 'bignumber.js'
import { Decimal } from "decimal.js";
import { getBep20Contract } from 'utils/contractHelpers'
import useAuth from 'hooks/useAuth'
import useToast from 'hooks/useToast'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { fetchChainOrderExecutedAdd } from 'api/api'
import { approve } from 'utils/callHelpers'
import { ButtonMenu, ButtonMenuItem, ConnectorNames } from '@dreamweb/uikit'
import { Button, Pagination, Input, Modal, Select, Tooltip, Table  } from 'antd'
import { useUpdateEffect } from 'ahooks'
import { forEach } from 'lodash'
import { FACTORY_ADDRESS } from '@dreamfinance-libs/sdk'
import { StyleContainer, TabMain, NFTs, BOXs, Gallery, BuyConfirm, BoxBuyConfirm, MdfBuyConfirm } from './indexStyles'


const StyleBase = styled.div``

const Wrapper1 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:  0px;
`

const GalleryTitle = styled(StyleBase)<{isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({isMobile}) => isMobile ?  '49px' : '22px' };
  font-size: ${({isMobile}) => isMobile ?  '64px' : '32px' };
  font-family: Nirmala UI-Bold;
  font-weight: 400;
  color: #FFFFFF;
`

const horse0 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse0.mp4"
const horse1 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse1.mp4"
const horse2 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse2.mp4"
const horse3 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse3.mp4"
const horse4 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse4.mp4"

const horseArr = [horse0,horse1,horse2,horse3,horse4]

const result = sessionStorage.getItem('gas') || 50000
let tabIndexG = new RegExp('/pegasus-nft').test(window.location.href)?0:
new RegExp('/mdf').test(window.location.href)?1:
new RegExp('/pokemon').test(window.location.href)?2:
new RegExp('/buy').test(window.location.href)?999:
''
let gallery = 2
export default function Index() {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const web3 = useWeb3()
  const history = useHistory()
  const { account } = useWeb3React()
  // const account = '0x62F7294C0DfE9882af4f5484668Fa3EF3eD62612'
  // const account = '0xfdE01097fd8b7d32215EFE40532e87Dda7a1E73C'
  // const account = '0xE1D28815B49d999A830BBAD89A70De2547CfA227'
  // const account = '0x8b391F830326D3Eef0B3274eA2e3eA554cffa1ff'
  // const account = '0xFb8c9A1043BdE81Eb7B2C3E5Ea5Ac1f2858935B1'
  // const account = '0x5B6597358fF2B58B495D6476e283e9e189E744FF'
  // const account = '0x0D6505Ad147572B7A7fF6683EC7ccea67d9D2D1A'
  // const account = '0xd6F4d8Fb13EF9635EB6febD8C0D7BEd967802335'
  // const account = '0xB1cC2Ab1cacA8B3AB1f05be2d96b89D0a66547Fe'
  const [tabIndex, setTabIndex] = useState(
    new RegExp('/pegasus-nft').test(window.location.href)?0:
    new RegExp('/mdf').test(window.location.href)?1:
    new RegExp('/pokemon').test(window.location.href)?2:
    new RegExp('/buy').test(window.location.href)?999:
    ''
  )
  const [ntabIndex, setNTabIndex] = useState(0)
  const usdtAddress = getUsdtAddress()
  const balance = useTokenBalance(usdtAddress);
  const [showSelect, setShowSelect] = useState<boolean>(false)
  const [nftBuyBox, setNftBuyBox] = useState<boolean>(false)
  const [galleryBuyBox, setGalleryBuyBox] = useState<boolean>(false)
  const [openTaransfer, setOpenTaransfer] = useState<boolean>(false)
  const [openBuy, setOpenBuy] = useState<boolean>(false)
  const [icon, setIcon] = useState(0)
  const [openSell, setOpenSell] = useState<boolean>(false)
  const [boxOpenBuy, setBoxOpenBuy] = useState<boolean>(false)
  const [openMdfBuy, setOpenMdfBuy] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectType, setSelectType] = useState(history?.location?.state?.selectType || 'ALL') 
  const [ipt1, setIpt1] = useState(); 
  const [currentPage,setCurrentPage]=useState(1)
  const [total,setTotal]=useState(0)
  const [lodaing, setLodaing] = useState(false)
  const [toAddress,setToAddress] = useState()
  const [buyNum,setBuyNum] = useState(0)
  const [toPrice,setToPrice] = useState()
  const [toId,setToId] = useState()
  const [levelCountArr,setLevelCountArr]=useState([])
  const [levelPriceArr,setLevelPriceArr]=useState([])
  const [idvs,setIdvs]=useState<any>()
  const [fee,setFee]=useState(0)
  const [sellContract,setSellContract]=useState({
    _contract: '',
    _price: 0,
  })
  const [isApproveNM, setIsApproveNM] = useState([])
  const [isApproveNM1, setIsApproveNM1] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [isTest, setIsTest] = useState(false)
  const [isIdText, setIsIdText] = useState(false)
  const [isTest1, setIsTest1] = useState(false)
  const [idvsIndex, setIdvsIndex]=useState(1)
  const [levelName,setLevelName]=useState('')
  const [iptMore, setIptMore] = useState();
  const [buyNftI, setBuyNftI] = useState(0);
  const { login, logout } = useAuth()
  const nftNameArr = [
    'Unicorn',
    'Pegasus Normal',
    'Pegasus Bronze',
    'Pegasus Silver',
    'Pegasus Gold',
    'MDF Normal',
    'MDF Premium',
    'Pokemon',
  ]
  const arrPegasusName=['Unicorn',
    'Pegasus Normal',
    'Pegasus Bronze',
    'Pegasus Silver',
    'Pegasus Gold']
  const arrMDFName = ['MDF Normal',
    'MDF Premium']
  const arrPokemoname = ['Pokemon']
  const [searcLevel,setSearchlevel]=useState<number>()
  

  const exchangeContract = getExchange(web3)

  // 一级市场和PokemonNFt
  const pokemonNftContract = getPokemonNft(web3)

  const nftContractMarket = getMarketNft(web3)

  const mdfContractMarket = getMarketMDF(web3)
  
  const nftContract = getPegasusNft(web3)
  
  const MDFNftContract = getMDFNft(web3)


  
  // NFTStaking
  const NftStakingContract = getNftStaking(web3)

  // 获取币选择列表
  const getCoin = async () => { 
    try {
      const res0 = await exchangeContract.methods.tokens(0).call()
      // console.log('getCoin0', res0)
      const res1 = await exchangeContract.methods.tokens(1).call()
      // console.log('getCoin1', res1)
      const res2 = await exchangeContract.methods.tokens(2).call()
      // console.log('getCoin2', res2)
      console.log('合约地址', getExchangeAddress(),await exchangeContract.methods)
    } catch (error) {
      // console.log(147, error)
    } 
  }
  

  const sell = async () => { 
    try {
      setLodaing(true)
      // id,交易币，池子，价格
      // console.log('sell', [idvs?.ids?.[idvsIndex]],icon,tabIndex,big1e18(toPrice||0))
      const hash = await exchangeContract.methods.sell([idvs?.ids?.[idvsIndex]],icon,tabIndex,big1e18(toPrice||0)).send({ from: account,
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
      console.log(222, hash, 'sellerId', toId)

      if(hash?.transactionHash) {
        fetchChainOrderExecutedAdd({
          txHash: hash?.transactionHash,
          sellerId: toId
        })
      }
      setToPrice(null)
      setToId(null)
      setIpt1(null)
      setOpenSell(false)
      setLodaing(false)
      setGalleryBuyBox(false)
      // console.log('用户转账NFT 总量 Do')
      toastSuccess('Sell successful')
      userLevelCount()
      getUserTokens()
    } catch (error) {
      // console.log(147, error)
      setLodaing(false)
    } 
  }

  // 判断是否授权
  const isApprove = async () => { 
    try {
      if(tabIndex===0){
        // const res = await nftContract.methods.isApprovedForAll(account, getNftStakingAddress()).call()
        const res1 = await nftContract.methods.isApprovedForAll(account, getExchangeAddress()).call()
        // setIsApproveNM(res)
        // console.log(210, res1)
        setIsApproveNM1(res1)
      } 
      if(tabIndex===1) {
        // console.log('isApproveMDFNftContract')
        // const res = await MDFNftContract.methods.isApprovedForAll(account, getNftStakingAddress()).call()
        const res1 = await MDFNftContract.methods.isApprovedForAll(account, getExchangeAddress()).call()
        // console.log('isApproveMDFNftContract', res)
        // setIsApproveNM(res)
        setIsApproveNM1(res1)
      }
      if(tabIndex===2) {
        console.log('pokemonNftContract')
        // const res = await MDFNftContract.methods.isApprovedForAll(account, getNftStakingAddress()).call()
        const res1 = await pokemonNftContract.methods.isApprovedForAll(account, getExchangeAddress()).call()
        // console.log('isApproveMDFNftContract', res)
        // setIsApproveNM(res)
        console.log('242', res1)
        setIsApproveNM1(res1)
      }
      
    } catch (error) {
      console.log(212, error)
    }
  }

  const nftApprove1 = async () => { 
    setLodaing(true)
    try {
      if(tabIndex===0){
        await nftContract.methods.setApprovalForAll(getExchangeAddress(), true).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
        // console.log('nftContract', nftContract.methods)
      } else if (tabIndex===1) {
        // console.log('MDFNftContract', nftContract.methods)
        await MDFNftContract.methods.setApprovalForAll(getExchangeAddress(), true).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
        // console.log('MDFNftContract', nftContract.methods)
      } else if (tabIndex===2) {
        // console.log('pokemonNftContract', nftContract.methods)
        await pokemonNftContract.methods.setApprovalForAll(getExchangeAddress(), true).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
        // console.log('MDFNftContract', nftContract.methods)
      }
      
    } catch (error) {
      console.log(247, error)
      setLodaing(false)
    }
    setLodaing(false)
    isApprove()
  }
  
  useEffect(() => { 
    // 新增项目放sell判断USDT是否授权
    isApprove()
    // getCoin()
  }, [tabIndex, account])

  const handleClick = async (index: number) => {
    setNftBuyBox(false)
    setGalleryBuyBox(false)
    setIpt1(null)
    setIptMore(null)
    setNTabIndex(0)
    setCurrentPage(1)
    setSelectType('All')
    if(index===0) {
      history.push('/nft/pegasus-nft')
    }else if(index===1) {
      history.push('/nft/mdf')
    }else if(index===2) {
      history.push('/nft/pokemon')
    }else {
      history.push('/nft/buy')
    }
  }
  const getFee = async () => { 
    try {
      const getNewFee = await exchangeContract.methods.feeRate().call()
      setFee(getNewFee)
      // console.log('feeRate', getNewFee)
    } catch (error) {
      // console.log('nftserror', error)
    }
  }
  useEffect(() => { 
    getFee()
  }, [])

  useEffect(() => { 
    const unListen = history.listen((e) => { 
      if(new RegExp('/buy').test(e.pathname)){
        tabIndexG=999
        setTabIndex(999)
        return
      }
      if(new RegExp('/pokemon').test(e.pathname)){
        setDataLoading(true)
        setTotal(0)
        setIdvs({
          ids: [],
          lvs: []
        })
        tabIndexG=2
        setTabIndex(2)
        getUserTokens(2)
        return
      }
      if(new RegExp('/pegasus-nft').test(e.pathname)) {
        if(tabIndexG!==0) {
          setDataLoading(true)
          setTotal(0)
          setIdvs({
            ids: [],
            lvs: []
          })
        }
        tabIndexG=0
        setTabIndex(0)
        getUserTokens(0)
      } else {
        if(tabIndexG!==1) {
          setDataLoading(true)
          setTotal(0)
          setIdvs({
            ids: [],
            lvs: []
          })
        }
        tabIndexG=1
        setTabIndex(1)
        getUserTokens(1)
      }
     })
    return ()=>{
      unListen()
    }
  }, [account])

  const stopPropagation = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
  }
  const selectFunc = (e: React.MouseEvent) => {
    stopPropagation(e)
    setShowSelect(!showSelect)
  }

    
  const handleChange = (e, set) => {
    const val = e.replace(/[^\d]/g, '');
    let num = val
    if (val === '0') {
        num = 1
    }
    set(num)
  }
  
  const changeNum = (e) => { 
    const val = /^[\da-z]+$/i.test(e);
    let reg = /^[0-9]{1,4}$/i;
    if(e==='') {
      setIsTest(false)

    } else {
      setIsTest(!reg.test(e))
    }
    if(e === '') {
      setBuyNum(e)
    }
    if(val) {
      setBuyNum(e)
    }
  }

  const changeToAddress = (e) => { 
    const val = /^[\da-z]+$/i.test(e);
    let reg = /^0x([a-fA-F0-9]){40}$/;
    if(e==='') {
      setIsTest(true)
    } else {
      setIsTest(!reg.test(e))
    }
    if(e === '') {
      setToAddress(e)
    }
    if(val) {
      setToAddress(e)
    }
  }
  const changePrice = (e) => { 
    let reg = /^[0-9]+(\.[0-9]{0,18})?$/;
    if(e==='') {
      setIsTest(true)
    } else {
      setIsTest(!reg.test(e))
    }
    if(e === '') {
      setToPrice(e)
    }
    if(reg.test(e)){
      setToPrice(e)
    }
  }

  const changeId = (e) => { 
    let reg = /[a-zA-Z0-9]{4,40}/;
    if(e==='') {
      setIsIdText(true)
    } else {
      setIsIdText(!reg.test(e))
    }
    if(e === '') {
      setToId(e)
    }
    console.log(460, reg.test(e))
    if(reg.test(e)){
      setToId(e)
    }
  }


  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);



  // 获取全部期数数据 view
  const getUserTokens = async (v?:any) => { 
    // console.log(241, v,tabIndex)
    setDataLoading(true)
    try {
      // console.log("开始获取全部期数数据 view", account,500,1)
      let term1 = {
        ids: [],
        lvs: []
      }
      if((new RegExp('/pegasus-nft').test(window.location.href)?0:1)===0) {
        const balance = await nftContract.methods.balanceOf(account).call()
        console.log(497, balance)
        setTotal(balance)   
        term1 = await getNFTsNoSearch(nftContract, account, currentPage)
        // term1 = await getNFTsStakingForSynchronous(nftContract, account)
        // term1 = await nftContract.methods.getUserTokens(account,10,1).call()
        console.log(420, term1)
      } else if(new RegExp('/mdf').test(window.location.href)) {  
        const balance = await MDFNftContract.methods.balanceOf(account).call()
        setTotal(balance)  
        term1 = await getNFTsNoSearch(MDFNftContract, account, currentPage)   
        // term1 = await getNFTsStakingForSynchronous(nftContract, account) 
      } else if(new RegExp('/pokemon').test(window.location.href)) {
        const balance = await pokemonNftContract.methods.balanceOf(account).call()
        setTotal(balance)     
        term1 = await getUserLevelTokens(pokemonNftContract, account)    
      }
      // if(selectType==='Unicorn') {
      //   const lvs = []; const ids=[]
      //   for (let index = 0; index < term1?.lvs?.length; index++) {
      //     if(term1?.lvs?.[index]==='0') {
      //       ids.push(term1?.ids?.[index]);
      //       lvs.push(term1?.lvs?.[index]);
      //     }
      //   }
      //   setIdvs({
      //     ids:[...ids].splice((currentPage-1)*8,8),
      //     lvs:[...lvs].splice((currentPage-1)*8,8)
      //   })
        
      //   // const balance = await contract.methods.balanceOf(account).call()
      //   // setTotal(lvs?.length)
      //   setDataLoading(false)
      //   return
      // }
      
      // if(selectType==='Pegasus Normal') {
      //   const lvs = []; const ids=[]
      //   for (let index = 0; index < term1?.lvs?.length; index++) {
      //     if(term1?.lvs?.[index]==='1') {
      //       ids.push(term1?.ids?.[index]);
      //       lvs.push(term1?.lvs?.[index]);
      //     }
      //   }
      //   setIdvs({
      //     ids:[...ids].splice((currentPage-1)*8,8),
      //     lvs:[...lvs].splice((currentPage-1)*8,8)
      //   })
      //   setTotal(lvs?.length)
      //   setDataLoading(false)
      //   return
      // }
      // if(selectType==='Pegasus Bronze') {
      //   const lvs = []; const ids=[]
      //   for (let index = 0; index < term1?.lvs?.length; index++) {
      //     if(term1?.lvs?.[index]==='2') {
      //       ids.push(term1?.ids?.[index]);
      //       lvs.push(term1?.lvs?.[index]);
      //     }
      //   }
      //   setIdvs({
      //     ids:[...ids].splice((currentPage-1)*8,8),
      //     lvs:[...lvs].splice((currentPage-1)*8,8)
      //   })
      //   setTotal(lvs?.length)
      //   setDataLoading(false)
      //   return
      // }
      // if(selectType==='Pegasus Silver') {
      //   const lvs = []; const ids=[]
      //   for (let index = 0; index < term1?.lvs?.length; index++) {
      //     if(term1?.lvs?.[index]==='3') {
      //       ids.push(term1?.ids?.[index]);
      //       lvs.push(term1?.lvs?.[index]);
      //     }
      //   }
      //   setIdvs({
      //     ids:[...ids].splice((currentPage-1)*8,8),
      //     lvs:[...lvs].splice((currentPage-1)*8,8)
      //   })
      //   setTotal(lvs?.length)
      //   setDataLoading(false)
      //   return
      // }
      // if(selectType==='Pegasus Gold') {
      //   const lvs = []; const ids=[]
      //   for (let index = 0; index < term1?.lvs?.length; index++) {
      //     if(term1?.lvs?.[index]==='4') {
      //       ids.push(term1?.ids?.[index]);
      //       lvs.push(term1?.lvs?.[index]);
      //     }
      //   }
      //   setTotal(ids?.length)
      //   setIdvs({
      //     ids:[...ids].splice((currentPage-1)*8,8),
      //     lvs:[...lvs].splice((currentPage-1)*8,8)
      //   })
      //   setDataLoading(false)
      //   return
      // }
      // if(selectType==='MDF Normal') {
      //   const lvs = []; const ids=[]
      //   for (let index = 0; index < term1?.lvs?.length; index++) {
      //     if(term1?.lvs?.[index]==='0') {
      //       ids.push(term1?.ids?.[index]);
      //       lvs.push(term1?.lvs?.[index]);
      //     }
      //   }
      //   setTotal(lvs?.length)
      //   setIdvs({
      //     ids:[...ids].splice((currentPage-1)*8,8),
      //     lvs:[...lvs].splice((currentPage-1)*8,8)
      //   })
      //   setDataLoading(false)
      //   return
      // }
      // if(selectType==='MDF Premium') {
      //   const lvs = []; const ids=[]
      //   for (let index = 0; index < term1?.lvs?.length; index++) {
      //     if(term1?.lvs?.[index]==='1') {
      //       ids.push(term1?.ids?.[index]);
      //       lvs.push(term1?.lvs?.[index]);
      //     }
      //   }
      //   setTotal(lvs?.length)
      //   setIdvs({
      //     ids:[...ids].splice((currentPage-1)*8,8),
      //     lvs:[...lvs].splice((currentPage-1)*8,8)
      //   })
      //   setDataLoading(false)
      //   return
      // }
      // if(selectType==='Pokemon') {
      //   const lvs = []; const ids=[]
      //   for (let index = 0; index < term1?.lvs?.length; index++) {
      //     if(term1?.lvs?.[index]==='0') {
      //       ids.push(term1?.ids?.[index]);
      //       lvs.push(term1?.lvs?.[index]);
      //     }
      //   }
      //   setTotal(lvs?.length)
      //   setIdvs({
      //     ids:[...ids].splice((currentPage-1)*8,8),
      //     lvs:[...lvs].splice((currentPage-1)*8,8)
      //   })
      //   setDataLoading(false)
      //   return
      // }
      const lvs = term1?.lvs; const ids= term1?.ids
      // setTotal(ids?.length)
      
      // console.log("当前展示页数据", {
      //   ids: [...ids].splice((currentPage-1)*8,8),
      //   lvs: [...lvs].splice((currentPage-1)*8,8)
      // })
      setIdvs({
        ids: [...ids],
        lvs: [...lvs]
      })
      // console.log('NFTS',{
      //   ids: [...ids].splice((currentPage-1)*8,8),
      //   lvs: [...lvs].splice((currentPage-1)*8,8)
      // })
      setDataLoading(false)
    } catch (error) {
      console.log(372, account, error)
      setDataLoading(false)
    }
    
    setDataLoading(false)
  }

  

  // 切换账号初始化
  useEffect(() => { 
    setToAddress(null)
    setGalleryBuyBox(false)
  }, [account])

  // 各等级卡片总数量userLevelCount
  const userLevelCount = async () => { 
    try {
      // console.log("各等级卡片总数量", account,)
      const arrTotal = await Promise.all(
        [0,1,2,3,4,5,6,7].map(async (item) => {
          let newTotal
          if(item<5){
            newTotal = await nftContract.methods.userLevelCount(account,item).call()
          } else if (item<7) {
            newTotal = await MDFNftContract.methods.userLevelCount(account,item===5?0:1).call()
          } else if (item<8) {
            newTotal = await pokemonNftContract.methods.userLevelCount(account,0).call()
          }
          return new Promise((resolve) => {
            resolve(newTotal)
          })
        })
      )
      setLevelCountArr(arrTotal)
      // console.log("各等级卡片总数量", arrTotal)
      // console.log('各等级卡片总数量', [newTotal0,newTotal1,newTotal2,newTotal3,newTotal4, newTotal5,newTotal6])
    } catch (error) {
      // console.log(error)
    }
  }

  // 用户转账NFT 总量 Do
  const transferFrom = async () => { 
    try {
      setLodaing(true)
      console.log("用户转账NFT 总量 Do",'pokemonNftContract',idvs?.ids?.[idvsIndex], idvs?.lvs?.[idvsIndex])
      await (tabIndex===0?nftContract:tabIndex===1?MDFNftContract:pokemonNftContract).methods.transferFrom(account,toAddress,idvs?.ids?.[idvsIndex]).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      })
      setToAddress(null)
      setIpt1(null)
      setOpenTaransfer(false)
      setLodaing(false)
      setGalleryBuyBox(false)
      // console.log('用户转账NFT 总量 Do')
      toastSuccess('Transfer successful')
      userLevelCount()
      getUserTokens()
    } catch (error: any) {
      setOpenTaransfer(false)
      setToAddress(null)
      setLodaing(false)
      toastWarning(error?.message ? error?.message : 'Transfer failed')
    }
  }


  

  // 获取当前展示
  useEffect(() => { 
    if(account) {
      // console.log(467, window.location.hash, tabIndex)
      getUserTokens()
      getLevelLength()
      userLevelCount()
    }
  }, [currentPage,account,selectType])

  useUpdateEffect(() => { 
    setCurrentPage(1)
  }, [selectType])

  
  // 获取LevelName view 
  const getLevelName = async (id) => { 
    try {
      // console.log("获取LevelName view", id)
      setLevelName('')
      const newLevelName = await (tabIndex===0?nftContract:tabIndex===1?MDFNftContract:pokemonNftContract).methods.getLevelName(id).call()
      // console.log('LevelName view', newLevelName)
      setLevelName(newLevelName)
    } catch (error) {
      // console.log(error)
    }
  }

  // 获取 Pool 数量 view
  const getLevelLength = async () => { 
    try {
      // console.log("Pool", )
      const newLength = await nftContract.methods.getLevelLength().call()
      // console.log('Pool-', newLength)
    } catch (error) {
      // console.log(error)
    }
  }

  // sell 一级市场获取交易获取交易价格
  const getLevelPrice = async () => {
    // const levelPriceArr =  await Promise.all([0,1,2,3,4,5,6,7].map(async (item)=>{
    //   if(item < 5) {
    //     const res = await nftContractMarket.methods.getLevelPrice(item).call()
    //     return res?._price
    //   } if(item < 7) {    
    //     const res = await mdfContractMarket.methods.getLevelPrice(
    //       item===5?0:1
    //     ).call()  
    //     return res?._price
    //   } if(item < 8) {   
    //     const res = await pokemonNftContract.methods.getLevelPrice(0).call()
    //     return res?._price
    //   }
    // }))
    // setLevelPriceArr(levelPriceArr)
    
    // console.log(717, resArr)
    try {
      console.log(727)
      setLodaing(true)
      const res = await pokemonNftContract.methods.getLevelPrice(0).call()
      setSellContract({
        ...res,
        _price: new Decimal(res?._price||0).div(1e18).toNumber()
      })
      console.log(728, res)
      setLodaing(false)
    } catch (error) {
      setLodaing(false)
      console.log(732, error)
    }
  }

  // 授权一级市场
  const approvalSell = async (index?: number) => {
    console.log(749, sellContract?._contract, index || buyNftI)
    setLodaing(true)
    try {
      if((index || buyNftI) < 5){
        await approve(getBep20Contract(sellContract?._contract, web3), getNftAddress(), account)
        // console.log('nftContract', nftContract.methods)
      } else if ((index || buyNftI) < 7) {
        console.log('MDFNftContract', nftContract.methods)
          await approve(getBep20Contract(sellContract?._contract, web3), getMDFNftAddress(), account)
        // console.log('MDFNftContract', nftContract.methods)
      } else if ((index || buyNftI) < 8) {
        console.log('pokemonNftContract', nftContract.methods)
          await approve(getBep20Contract(sellContract?._contract, web3), getPokemonNftAddress(), account)
        // console.log('MDFNftContract', nftContract.methods)
      }
      isApproveSell()
      setLodaing(false)
    } catch (error) {
      console.log(767, error)
      setLodaing(false)
    }
  }
  // 判断一级市场是否授权
  const isApproveSell = async () => { 
    try {
      console.log(771)
      const res = await Promise.all([
        await getBep20Contract(sellContract?._contract, web3).methods.allowance(account, getNftAddress()).call(),
        await getBep20Contract(sellContract?._contract, web3).methods.allowance(account, getMDFNftAddress()).call(),
        await getBep20Contract(sellContract?._contract, web3).methods.allowance(account, getPokemonNftAddress()).call(),
      ])
      setIsApproveNM(res)
      console.log(774, res)
    } catch (error) {
      console.log(773, error)
    }
  }

  useEffect(()=>{
    if(sellContract?._contract){
      isApproveSell()
    }
  }, [sellContract?._contract, account])

  const buyMarket = async (amount) => {
    setLodaing(true)
    if(amount > new BigNumber(balance || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()) {
      // console.log(837, amount , new BigNumber(balance || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber())
      
      setLodaing(false)
      toastWarning('Insufficient balance')
      return
    }
    try {
        if(buyNftI < 5) {
          console.log(741, buyNftI, buyNum)
          const res = await nftContractMarket.methods.buy(buyNftI,buyNum).send({
            from: account, 
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          })
          toastSuccess('Buy successful')
          setOpenBuy(false)
          setLodaing(false)
          return res
        } 
        if(buyNftI < 7) {    
          console.log(742, buyNftI===5?0:1, buyNum)
          const res = await mdfContractMarket.methods.buy(buyNftI===5?0:1,buyNum).send({
            from: account, 
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          })  
          toastSuccess('Buy successful')
          setOpenBuy(false)
          setLodaing(false)
          return res
        } 
        if(buyNftI < 8) {   
          console.log(743, 0, buyNum)
          const res = await pokemonNftContract.methods.buy(0,buyNum).send({
            from: account, 
            gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          })
          toastSuccess('Buy successful')
          setOpenBuy(false)
          setLodaing(false)
          return res
        }
    } catch (error) {
      console.log(744, error)
      setLodaing(false)
    }
  }

  useEffect(()=>{
    getLevelPrice()
  }, [])

  useUpdateEffect(() => { 
    // console.log(505, idvs?.ids?.[idvsIndex])
    // getLevel(idvs?.ids?.[idvsIndex])
    getLevelName(idvs?.lvs?.[idvsIndex])
    // tokenURI(idvs?.ids?.[idvsIndex])
  }, [idvsIndex])

  useEffect(() => { 
    tabIndexG=tabIndex
  }, [tabIndex])


  useEffect(() => { 
    changeToAddress('')
    changeNum('')
  }, [openTaransfer])

  return (
    <Wrapper isMobile={matches}>
      <main className='roll'>
        <div className='main'>
          <GalleryTitle isMobile={matches}>
            <div>{!ntabIndex?'GALLERY':'GALLERY'}</div>
          </GalleryTitle>
          {/* <div onClick={aaa}>aaa</div> */}
          {/* <Wrapper1 style={{justifyContent: matches ? 'center' : 'center'}}>
            <StyleContainer isMobile={matches}  style={{marginTop: matches? '0rem' : '0px', width: matches ? '400px' : '400px' }}>
              <ButtonMenu style={{ width: '100%', height: '41px', backgroundColor: '#232323', borderRadius: '9px' }} activeIndex={tabIndex} onItemClick={handleClick} scale="sm" >
                <ButtonMenuItem style={{ width: '50%', height: '41px',backgroundColor: tabIndex === 0 ? '#fff': '', borderRadius: '8px', color: tabIndex === 0 ? '#000': '#8A8A8A'}}>Pegasus NFT</ButtonMenuItem>
                <ButtonMenuItem style={{ width: '50%', height: '41px',backgroundColor: tabIndex === 1 ? '#fff': '', borderRadius: '8px', color: tabIndex === 1 ? '#000': '#8A8A8A'}}>MDF</ButtonMenuItem>
              </ButtonMenu>
            </StyleContainer>
          </Wrapper1> */}
          {/* 一级市场功能 */}
          <Wrapper1 style={{justifyContent: matches ? 'center' : 'center'}}>
            <StyleContainer isMobile={matches}  style={{marginTop: matches? '0rem' : '0px', width: matches ? '400px' : '400px' }}>
              <div style={{
                  width: '100%',
                  height: '41px',
                  backgroundColor: 'rgb(35, 35, 35)',
                  borderRadius: '9px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',}}>
                    <div style={tabIndex === 999?{
                          width: '15%',
                          height: '41px',
                          backgroundColor: 'rgb(255, 255, 255)',
                          borderRadius: '8px',
                          color: 'rgb(0, 0, 0)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',}:{
                            width: '15%',
                            height: '41px',
                            backgroundColor: 'rgb(35, 35, 35)',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#8A8A8A'}}
                          onClick={()=>{
                            handleClick(999)
                          }}
                          >
                            Buy
                    </div>
                    <div style={tabIndex === 0?{
                          width: '35%',
                          height: '41px',
                          backgroundColor: 'rgb(255, 255, 255)',
                          borderRadius: '8px',
                          color: 'rgb(0, 0, 0)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',}:{
                            width: '35%',
                            height: '41px',
                            backgroundColor: 'rgb(35, 35, 35)',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#8A8A8A'}}
                          onClick={()=>{
                            handleClick(0)
                          }}>
                            Pegasus NFT
                    </div>
                    <div style={tabIndex === 1?{
                          width: '15%',
                          height: '41px',
                          backgroundColor: 'rgb(255, 255, 255)',
                          borderRadius: '8px',
                          color: 'rgb(0, 0, 0)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',}:{
                            width: '15%',
                            height: '41px',
                            backgroundColor: 'rgb(35, 35, 35)',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#8A8A8A'}}
                          onClick={()=>{
                            handleClick(1)
                          }}>
                            MDF
                    </div>
                    <div style={tabIndex === 2?{
                          width: '35%',
                          height: '41px',
                          backgroundColor: 'rgb(255, 255, 255)',
                          borderRadius: '8px',
                          color: 'rgb(0, 0, 0)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',}:{
                            width: '35%',
                            height: '41px',
                            backgroundColor: 'rgb(35, 35, 35)',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#8A8A8A'}}
                          onClick={()=>{
                            handleClick(2)
                          }}>
                            Game Card
                    </div>
              </div>
            </StyleContainer>
          </Wrapper1>
          <TabMain isMobile={matches}>
            {/* 判断Sell模块 */}
            {
              tabIndex === 999 ? 
              <>
              <ComingSoon>
                <div style={{marginTop: '120px'}} className="main">
                  <img src="/images/ComingSoon.png" alt="" />
                  <div>
                    Coming Soon
                  </div>
                </div>
              </ComingSoon>
               {/* <Gallery isMobile={matches}>
                {
                  galleryBuyBox ? 
                    <div className='buy-box'>
                      <div onClick={()=>{
                        setGalleryBuyBox(false)
                      }} style={{display:'flex',alignItems:'center',position:'absolute',top:'24px',left:matches?'24px':'15px'}}>
                        <img style={{width:24,marginRight:5}} src="/images/change/back.png" alt="" />
                        <div>Go back</div>
                      </div>
                      <div className='img'>
                        {
                          buyNftI < 5 && sessionStorage.getItem('navigatorName') === '-1' ? 
                          <video 
                            x5-playsinline 
                            webkit-playsinline 
                            x5-video-player-type="h5" 
                            x5-video-player-fullscreen
                            x5-video-orientation="portraint" 
                            playsInline  loop autoPlay muted controls={false}>
                              <source src={horseArr?.[buyNftI]}/>
                          </video> :
                          ''
                        }
                        {
                          buyNftI < 5 && sessionStorage.getItem('navigatorName') !== '-1' ? 
                          <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${buyNftI}.png`} alt="" /> :
                          ''
                        }
                        {
                          buyNftI >= 5 && buyNftI <= 6 ? 
                          <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${buyNftI}.png`} alt="" />
                          : ''
                        }
                        {
                          +buyNftI === 7 ? 
                          <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/pokemon/images/Pokemon.JPG" alt="" /> : ''
                        }
                      </div>
                      <div style={{width:matches?'480px':'100%',marginTop:15,padding:matches?'':'0 10px'}} className='flex justify-center items-center'>
                        <div>Name:</div>
                        &nbsp;
                        <div>{nftNameArr?.[buyNftI]}</div>
                      </div>
                      <div style={{width:matches?'480px':'100%',marginTop:15,padding:matches?'':'0 10px'}} className='flex justify-center items-center'>
                        <div>Coin:</div>
                        &nbsp;
                        <div>
                          {sellContract?._price||0}&nbsp;&nbsp;USDT
                          </div>
                      </div>
                      <div className='btn'>
                        <>
                            <Button  loading={lodaing} style={{marginRight: matches ?  '35px' : '' }}
                            onClick={(e)=>{
                              e.stopPropagation() 
                              if(buyNftI < 5 &&  +isApproveNM?.[0] > 0) {
                                setOpenBuy(true)
                              } else if ((buyNftI === 5 || buyNftI === 6) &&  +isApproveNM?.[1]) {
                                setOpenBuy(true)
                              } else if (buyNftI === 7 &&  +isApproveNM?.[2] > 0) {
                                setOpenBuy(true)
                              } else {
                                approvalSell()
                              }
                            }}>
                              
                              {
                                buyNftI < 5 &&  +isApproveNM?.[0] > 0 ? 'Buy' : 
                                (buyNftI === 5 || buyNftI === 6) &&  +isApproveNM?.[1] > 0 ? 'Buy' : 
                                buyNftI === 7 &&  +isApproveNM?.[2] > 0 ? 'Buy' : 'Approve'
                              }
                              </Button>
                          </>
                      </div>
                    </div> : 
                    <div className='content'>
                      {
                        [0,1,2,3,4,5,6,7].map((item)=>{
                          if(item >= 5 && item <= 6) {
                            return ''
                            // return <div className='img'>
                            //   <img 
                            //     onClick={()=>{
                            //       setBuyNftI(item)
                            //       setGalleryBuyBox(true)
                            //     }} src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${item}.png`} alt="" />
                            //   <Button loading={lodaing}  style={{marginTop: '24px',color:'#fff',border: '1px solid #fff', fontFamily: 'Nirmala UI-Regular'}} 
                            //     onClick={()=>{
                            //         setBuyNftI(item)
                            //       if(+isApproveNM?.[1] > 0) {
                            //         setOpenBuy(true)
                            //       } else {
                            //         approvalSell(item)
                            //       }
                            //     }}>
                            //     {
                            //       +isApproveNM?.[1] > 0 ? 'Buy' : 'Approval'
                            //     }
                            //   </Button>
                            // </div>
                          }
                          if (item > 6) {
                            return <div className='img'>
                            <img 
                              onClick={()=>{
                                setBuyNftI(item)
                                setGalleryBuyBox(true)
                              }} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/pokemon/images/Pokemon.JPG" alt="" />
                            <Button loading={lodaing}  style={{marginTop: '24px',color:'#fff',border: '1px solid #fff', fontFamily: 'Nirmala UI-Regular'}} 
                              onClick={()=>{
                                console.log(1112, item)
                                setBuyNftI(item)
                                if(+isApproveNM?.[2] > 0) {
                                  setOpenBuy(true)
                                } else {
                                  approvalSell(item)
                                }
                              }}>
                              {
                                +isApproveNM?.[2] > 0 ? 'Buy' : 'Approval'
                              }
                            </Button>
                          </div>
                          }
                          if(item < 5 && sessionStorage.getItem('navigatorName') === '-1') {
                            return ''
                            // return <div className='img'>
                            //   <video 
                            //     x5-playsinline 
                            //     webkit-playsinline 
                            //     x5-video-player-type="h5" 
                            //     x5-video-player-fullscreen
                            //     x5-video-orientation="portraint" 
                            //     playsInline 
                            //     onClick={()=>{
                            //       setBuyNftI(item)
                            //       setGalleryBuyBox(true)
                            //   }} loop autoPlay muted controls={false}>
                            //       <source src={horseArr?.[item]}/>
                            //   </video> 
                            //   <Button loading={lodaing}  style={{marginTop: '24px',color:'#fff',border: '1px solid #fff', fontFamily: 'Nirmala UI-Regular'}}
                            //     onClick={()=>{
                            //           setBuyNftI(item)
                            //       if(+isApproveNM?.[0] > 0) {
                            //         setOpenBuy(true)
                            //       } else {
                            //         approvalSell(item)
                            //       }
                            //     }}>
                            //     {
                            //       +isApproveNM?.[0] > 0 ? 'Buy' : 'Approval'
                            //     }
                            //   </Button>
                            // </div>
                          } 
                          if(item < 5 && sessionStorage.getItem('navigatorName') !== '-1') {
                            return ''
                          //   return <div className='img'>
                          //   <img 
                          //     onClick={()=>{
                          //       setBuyNftI(item)
                          //       setGalleryBuyBox(true)
                          //     }} src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${item}.png`} alt="" />
                          //   <Button loading={lodaing}  style={{marginTop: '24px',color:'#fff',border: '1px solid #fff', fontFamily: 'Nirmala UI-Regular'}} 
                          //     onClick={()=>{
                          //         setBuyNftI(item)
                          //       if(+isApproveNM?.[1] > 0) {
                          //         setOpenBuy(true)
                          //       } else {
                          //         approvalSell(item)
                          //       }
                          //     }}>
                          //     {
                          //       +isApproveNM?.[1] > 0 ? 'Buy' : 'Approval'
                          //     }
                          //   </Button>
                          // </div>
                          }
                          
                          

                          

                        })
                      }
                      
                    </div>
                }
               </Gallery> */}
              </> : 
              <>
                {
                  gallery === 2 && <>
                  {
                    !account ? 
                    <Login
                        style={{height: matches ? '600px' : '300px'}}
                        setIsModalOpen={setIsModalOpen}
                        isModalOpen={isModalOpen}
                    >
                      <div style={{width: '234px',height: '56px',background: '#fff', }} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
                    </Login>
                  : 
                    <Gallery isMobile={matches}>
                      {
                        !galleryBuyBox &&
                        <div className='tab'>
                          <div className='n-box'>
                            <div className={ntabIndex===0?'action corsor-pointer':'corsor-pointer'} onClick={()=>{setNTabIndex(0)}}>
                              1:1
                            </div>
                            <div className={ntabIndex===1?'action corsor-pointer':'corsor-pointer'} onClick={()=>{setNTabIndex(1)}}>
                              1:N
                            </div>
                          </div>
                          {
                            ntabIndex === 0 && 
                            <div style={{border: '1px solid #FFFFFF'}}  className="select-box mr-16 relative flex justify-start items-center cursor-pointer px-4 h-8 border border-line rounded-90" onClick={(e) => selectFunc(e)}>
                              <span className="w-26 text-text2 text-4xl">{selectType}</span>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/M3.png" alt="" style={{marginLeft:'5px',width: '12px', height: '6.46px',}}/>
                              {
                                tabIndex === 0 ? 
                                <>
                                {
                                  showSelect && (
                                    <div className="flex flex-col justify-around items-start absolute left-0 top-8 bg-black2 py-4 px-5 text-4xl text-text1 rounded-8 z-10 w-full">
                                      <span style={{display:'block',width:'100%'}} className="hover:text-text2 cursor-pointer" onClick={() => {
                                        setSelectType('ALL') 
                                        setSearchlevel(9)
                                        setGalleryBuyBox(false)}}>ALL</span>
                                      {/* <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                        setSelectType('Unicorn')   
                                        setSearchlevel(0)
                                        setGalleryBuyBox(false)}}>Unicorn</span>
                                      <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                        setSelectType('Pegasus Normal')   
                                        setSearchlevel(1)
                                        setGalleryBuyBox(false)}}>Pegasus Normal</span>
                                      <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                        setSelectType('Pegasus Bronze')  
                                        setSearchlevel(2) 
                                        setGalleryBuyBox(false)}}>Pegasus Bronze</span>
                                      <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                        setSelectType('Pegasus Silver')   
                                        setSearchlevel(3)
                                        setGalleryBuyBox(false)}}>Pegasus Silver</span>
                                      <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                        setSelectType('Pegasus Gold')  
                                        setSearchlevel(4) 
                                        setGalleryBuyBox(false)}}>Pegasus Gold</span> */}
                                    </div>
                                  )
                                }
                                </> : 
                                ''
                              }
                              {
                                tabIndex === 1 ? 
                                <>
                                {
                                  showSelect && (
                                    <div className="flex flex-col justify-around items-start absolute left-0 top-8 bg-black2 py-4 px-5 text-4xl text-text1 rounded-8 z-10 w-full">
                                      <span style={{display:'block',width:'100%'}} className="hover:text-text2 cursor-pointer" onClick={() => {
                                        setSelectType('ALL') 
                                        setSearchlevel(9)
                                        setGalleryBuyBox(false)}}>ALL</span>
                                      {/* <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                        setSelectType('MDF Normal')   
                                        setSearchlevel(4)
                                        setGalleryBuyBox(false)}}>MDF Normal</span>
                                      <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                        setSelectType('MDF Premium')   
                                        setSearchlevel(5)
                                        setGalleryBuyBox(false)}}>MDF Premium</span> */}
                                    </div>
                                  )
                                }
                                </> : ''
                              }
                              {
                                tabIndex === 2 ? 
                                <>
                                {
                                  showSelect && (
                                    <div className="flex flex-col justify-around items-start absolute left-0 top-8 bg-black2 py-4 px-5 text-4xl text-text1 rounded-8 z-10 w-full">
                                      <span style={{display:'block',width:'100%'}} className="hover:text-text2 cursor-pointer" onClick={() => {
                                        setSelectType('ALL') 
                                        setSearchlevel(9)
                                        setGalleryBuyBox(false)}}>ALL</span>
                                      {/* <span className="hover:text-text2 cursor-pointer pt-2" style={{whiteSpace: 'nowrap',display:'block',width:'100%'}} onClick={() => {
                                        setSelectType('Pokemon')   
                                        setSearchlevel(4)
                                        setGalleryBuyBox(false)}}>Pokemon</span> */}
                                    </div>
                                  )
                                }
                                </> : ''
                              }
                            </div>
                          }
                        </div>
                      }
                    {
                      ntabIndex ? 
                      <>
                        {
                          tabIndex === 0 &&
                          <div className='content'>
                            <div className='img'>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse0.png" alt="" />
                              <div style={{marginTop: '24px'}}>
                                COUNT: {levelCountArr?.[0]}
                              </div>
                            </div>
                            <div className='img'>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse1.png" alt="" />
                              <div style={{marginTop: '24px'}}>
                                COUNT: {levelCountArr?.[1]}
                              </div>
                            </div>
                            <div className='img'>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse2.png" alt="" />
                              <div style={{marginTop: '24px'}}>
                                COUNT: {levelCountArr?.[2]}
                              </div>
                            </div>
                            <div className='img'>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse3.png" alt="" />
                              <div style={{marginTop: '24px'}}>
                                COUNT: {levelCountArr?.[3]}
                              </div>
                            </div>
                            <div className='img'>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse4.png" alt="" />
                              <div style={{marginTop: '24px'}}>
                                COUNT: {levelCountArr?.[4]}
                              </div>
                            </div>
                          </div>
                        }
                        {
                          tabIndex === 1 &&
                          <div className='content'>
                            <div className='img'>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse5.png" alt="" />
                              <div style={{marginTop: '24px'}}>
                                COUNT: {levelCountArr?.[5]}
                              </div>
                            </div>
                            <div className='img'>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse6.png" alt="" />
                              <div style={{marginTop: '24px'}}>
                                COUNT: {levelCountArr?.[6]}
                              </div>
                            </div>
                          </div>
                        }
                        {
                          tabIndex === 2 &&
                          <div className='content'>
                            <div className='img'>
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/pokemon/images/Pokemon.JPG" alt="" />
                              <div style={{marginTop: '24px'}}>
                                COUNT: {levelCountArr?.[7]}
                              </div>
                            </div>
                          </div>
                        }
                      </> :
                      <>
                      {
                        !galleryBuyBox ? 
                        <>
                        {
                          idvs?.ids?.length ? 
                          <>
                            <div className='content'>
                              {
                                idvs?.ids?.map((id,i) => { 
                                  return <div key={`${id}${i}`} className='img'>
                                    {
                                      tabIndex===0 && sessionStorage.getItem('navigatorName') === '-1' ? 
                                      <video 
                                        x5-playsinline 
                                        webkit-playsinline 
                                        x5-video-player-type="h5" 
                                        x5-video-player-fullscreen
                                        x5-video-orientation="portraint" 
                                        playsInline 
                                        onClick={()=>{
                                        setGalleryBuyBox(true)
                                        setIdvsIndex(i)
                                      }} loop autoPlay muted controls={false}>
                                          <source src={horseArr?.[idvs?.lvs[i]]}/>
                                      </video> : ''
                                    }
                                    {
                                      tabIndex===0 && sessionStorage.getItem('navigatorName') !== '-1' ? 
                                      <img onClick={()=>{
                                          setIdvsIndex(i)
                                          setGalleryBuyBox(true)
                                      }} src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${idvs?.lvs[i]}.png`} alt="" /> : ''
                                    }
                                    {
                                      tabIndex===1 ? 
                                      <img onClick={()=>{
                                          setIdvsIndex(i)
                                          setGalleryBuyBox(true)
                                      }} src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${idvs?.lvs[i]==='0'?5:6}.png`} alt="" />
                                      : ''
                                    }
                                    {
                                      tabIndex===2 ? 
                                      <img onClick={()=>{
                                          setIdvsIndex(i)
                                          setGalleryBuyBox(true)
                                      }} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/pokemon/images/Pokemon.JPG" alt="" />
                                      : ''
                                    }
                                    <Button loading={lodaing}  style={{marginTop: '24px',color:'#fff',border: '1px solid #fff', fontFamily: 'Nirmala UI-Regular'}}>
                                      <Tooltip  placement="bottom" title={
                                        !lodaing ? <ShowMenu>
                                          <div 
                                          className='hover:text-text2 text-text1 corsor-pointer'
                                          onClick={(e)=>{
                                            e.stopPropagation()
                                            setIdvsIndex(i)
                                            setOpenTaransfer(true)
                                            }}>
                                              Transfer
                                            </div>
                                            {
                                              tabIndex !== 2 &&
                                              <div 
                                              className='hover:text-text2 text-text1 corsor-pointer'
                                              onClick={(e)=>{
                                                e.stopPropagation()
                                                history.push('/staking/pegasus')
                                                }}>Staking</div>
                                            }
                                            <div 
                                            className='hover:text-text2 text-text1 corsor-pointer'
                                            onClick={(e)=>{
                                              e.stopPropagation()
                                              if(isApproveNM1) {
                                                setIdvsIndex(i)
                                                setOpenSell(true)
                                              } else {
                                                nftApprove1()
                                              }
                                              }}>
                                                
                                              {
                                                !isApproveNM1 ? 'Approve MarketPlace' : 'Sell'
                                              }
                                              </div>
                                        </ShowMenu>:null}>
                                        Menu
                                      </Tooltip>
                                    </Button>
                                  </div>
                                })
                              }
                            </div>
                            {
                              +total > 8 &&
                              <div className='page'>
                                <Pagination onChange={(e)=>{
                                  setIdvs({
                                    ids: [],
                                    lvs: []
                                  })
                                  setCurrentPage(e)
                                }} current={currentPage} total={total} pageSize={8} showSizeChanger={false}/>
                              </div>
                            }
                          </> : 
                          <div className='no-Data'>{dataLoading?'loading':'No Data'}</div>
                        }
                        </> : 
                        <div className='buy-box'>
                          <div onClick={()=>{
                            setGalleryBuyBox(false)
                          }} style={{display:'flex',alignItems:'center',position:'absolute',top:'24px',left:matches?'24px':'15px'}}>
                            <img style={{width:24,marginRight:5}} src="/images/change/back.png" alt="" />
                            <div>Go back</div>
                          </div>
                          {/* <div className='title'>{levelName}</div> */}
                          <div className='img'>
                            {
                              tabIndex === 0 && sessionStorage.getItem('navigatorName') === '-1' ? 
                              <video 
                                x5-playsinline 
                                webkit-playsinline 
                                x5-video-player-type="h5" 
                                x5-video-player-fullscreen
                                x5-video-orientation="portraint" 
                                playsInline  loop autoPlay muted controls={false}>
                                  <source src={horseArr?.[idvs?.lvs?.[idvsIndex]]}/>
                              </video> : 
                              ''
                            }
                            {
                              tabIndex === 0 && sessionStorage.getItem('navigatorName') !== '-1' ? 
                              <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${idvs?.lvs?.[idvsIndex]}.png`} alt="" /> : 
                              ''
                            }
                            {
                              tabIndex===1 ? 
                              <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${idvs?.lvs?.[idvsIndex]==='0'?5:6}.png`} alt="" />
                              : ''
                            }
                            {
                              tabIndex===2 ? 
                              <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/pokemon/images/Pokemon.JPG" alt="" />
                              : ''
                            }
                          </div>
                          <div style={{width:matches?'480px':'100%',marginTop:15,padding:matches?'':'0 10px'}} className='flex justify-center items-center'>
                            <div>Name:</div>
                            &nbsp;
                            <div>{
                            tabIndex===0?arrPegasusName?.[idvs?.lvs?.[idvsIndex]]
                            :tabIndex===1?arrMDFName?.[idvs?.lvs?.[idvsIndex]]
                            :tabIndex===2?arrPokemoname?.[idvs?.lvs?.[idvsIndex]]:''
                            }</div>
                          </div>
                          <div style={{width:matches?'480px':'100%',marginTop:15,padding:matches?'':'0 10px'}} className='flex justify-center items-center'>
                            <div>NFT ID:</div>&nbsp;
                            <div>{idvs?.ids?.[idvsIndex]} 
                            </div>
                          </div>
                          <div className='btn'>
                            <>
                                <Button  loading={lodaing} style={{marginRight: matches ?  '35px' : '' }}
                                onClick={(e)=>{
                                  e.stopPropagation()
                                  setOpenTaransfer(true)
                                }}>Transfer
                                  </Button>
                                  
                                  {
                                    tabIndex !== 2 &&
                                  <Button  style={{marginRight: matches ?  '35px' : '' }} loading={lodaing} 
                                    onClick={()=>{
                                      history.push(tabIndex===0?'/staking/pegasus':'/staking/mdf')
                                      // stakeNft([idvs?.ids?.[idvsIndex]],idvs?.lvs?.[idvsIndex])
                                      }
                                    }
                                    >Staking</Button>
                                  }
                                  <Button  loading={lodaing}  onClick={()=>{
                                    if(isApproveNM1) {
                                      setOpenSell(true)
                                    } else {
                                      nftApprove1()
                                    }
                                  }}>
                                    {
                                      !isApproveNM1 ? 'Approve MarketPlace' : 'Sell'
                                    }
                                    </Button>
                              </>
                          </div>
                        </div>
                      }
                      </>
                    }
                      </Gallery>
                    }
                  </>
                }
              </>
            
            }
          </TabMain>
        </div>
      </main>
      
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openTaransfer} closable={false}>
          <BuyConfirm isMobile={matches}>
            <div className='title'>{tabIndex===0?'TRANSFER':'TRANSFER'}</div>
            <div className='amount-box'>
              <div>Name:</div><div>{
              tabIndex===0?arrPegasusName?.[idvs?.lvs?.[idvsIndex]]
              :tabIndex===1?arrMDFName?.[idvs?.lvs?.[idvsIndex]]||levelName
              :tabIndex===2?arrPokemoname?.[idvs?.lvs?.[idvsIndex]]||levelName : ''
              }</div>
            </div>
            <div className='amount-box'>
              <div>Address:</div>
              <div style={matches?{width:'270px',position:'relative'}:{position:'relative'}}>
                <Input 
                  value={toAddress} 
                  onBlur={()=>{
                    if(!toAddress) {
                      setIsTest(true)
                    }
                  }}
                  onChange={e => {
                        changeToAddress(e.target.value)
                      }}
                      />
                {
                  isTest &&
                  <div style={{position:'absolute', left: 0, bottom: '-28px',fontSize:'12px',color:'red'}}>invalid address</div>
                }
                </div>
            </div>
            {/* <div className='nft-box'>
              <div>Total price:</div><div>{percentileSegmentation(ipt1 && 100 * ipt1)} USDT</div>
            </div> */}
            <div className='btn btn-change'>
              <Button style={{border:'none'}} loading={lodaing} className='Cancle'  onClick={()=>{
                setOpenTaransfer(false)
                changeToAddress('')
              }}>Cancel</Button>
              <Button disabled={!toAddress} style={{border:'none', color: '#000', opacity: !toAddress ? 0.8 : 1}} loading={lodaing} onClick={()=>{
                transferFrom()
                }}>Confirm</Button>
            </div>
          </BuyConfirm>
      </Modal>       
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openBuy} closable={false}>
          <BuyConfirm isMobile={matches}>
            <div className='title'>Buy</div>
            <div className='amount-box'>
              <div>Name: </div>{nftNameArr?.[buyNftI]}
            </div>
            <div className='amount-box'>
              <div>Coin: </div>{(buyNum)*(
                // levelPriceArr?.[buyNftI]||0
                sellContract?._price||0
                )} &nbsp;&nbsp; USDT
            </div>
            <div className='amount-box'>
              <div>Num:</div>
              <div style={matches?{width:'270px',position:'relative'}:{position:'relative'}}>
                <Input 
                  value={buyNum} 
                  onChange={e => {
                    changeNum(e.target.value)
                      }}
                      />
                {
                  isTest &&
                  <div style={{position:'absolute', left: 0, bottom: '-28px',fontSize:'12px',color:'red'}}>invalid number</div>
                }
                </div>
            </div>
            {/* <div className='nft-box'>
              <div>Total price:</div><div>{percentileSegmentation(ipt1 && 100 * ipt1)} USDT</div>
            </div> */}
            <div className='btn btn-change'>
              <Button style={{border:'none'}} loading={lodaing} className='Cancle'  onClick={()=>{
                setOpenBuy(false)
                changeNum('')
              }}>Cancel</Button>
              <Button disabled={!buyNum} loading={lodaing} style={{border:'none', color: '#000', opacity: !buyNum ? 0.8 : 1}} onClick={()=>{
                if(buyNftI < 5 &&  +isApproveNM?.[0] > 0) {
                  buyMarket(buyNum*sellContract?._price)
                } else if ((buyNftI === 5 || buyNftI === 6) &&  +isApproveNM?.[1]) {
                  buyMarket(buyNum*sellContract?._price)
                } else if (buyNftI === 7 &&  +isApproveNM?.[2] > 0) {
                  buyMarket(buyNum*sellContract?._price)
                } else {
                  approvalSell()
                }
                }}>
                  {
                    buyNftI < 5 &&  +isApproveNM?.[0] > 0 ? 'Confirm' : 
                    (buyNftI === 5 || buyNftI === 6) &&  +isApproveNM?.[1] > 0 ? 'Confirm' : 
                    buyNftI === 7 &&  +isApproveNM?.[2] > 0 ? 'Confirm' : 'Approve'
                  }
                </Button>
            </div>
          </BuyConfirm>
      </Modal>           
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openSell} closable={false}>
          <BuyConfirm isMobile={matches}>
            <div className='title'>Sell</div>
            <div className='amount-box'>
              <div>Name:</div><div>{tabIndex===0?arrPegasusName?.[idvs?.lvs?.[idvsIndex]]:arrMDFName?.[idvs?.lvs?.[idvsIndex]]||levelName}</div>
            </div>
            <div className='amount-box'>
              <div>Coin:</div>
              <div  style={matches?{width:'270px',position:'relative'}:{width:'183px',position:'relative'}}>
              <Select
                style={{ width: '100%' }}
                optionFilterProp="children"
                onChange={(e)=>{
                  // console.log(1,e)
                  setIcon(e)
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[
                  {
                    value: '0',
                    label: 'USDT',
                  },
                  {
                    value: '1',
                    label: 'DCOIN',
                  },
                  {
                    value: '2',
                    label: 'KYURYO',
                  },
                ]}
              />
              </div>
            </div>
            <div className='amount-box'>
              <div>Seller Id:</div>
              <div style={matches?{width:'270px',position:'relative'}:{width:'183px',position:'relative'}}>
                  <Input 
                      value={toId} 
                      onBlur={()=>{
                        if(!toId) {
                          setIsIdText(true)
                        }
                      }}
                      onChange={e => {
                          changeId(e.target.value)
                          }}
                      />
                {
                  isIdText &&
                  <div style={{position:'absolute', left: 0, bottom: '-28px',fontSize:'12px',color:'red'}}>invalid Id</div>
                }
                </div>
            </div>
            <div className='amount-box'>
              <div>Price:</div>
              <div style={matches?{width:'270px',position:'relative'}:{width:'183px',position:'relative'}}>
                <Input 
                  value={toPrice} 
                  onBlur={()=>{
                    if(!toPrice) {
                      setIsTest(true)
                    }
                  }}
                  onChange={e => {
                      changePrice(e.target.value)
                      }}
                    />
                {
                  isTest &&
                  <div style={{position:'absolute', left: 0, bottom: '-28px',fontSize:'12px',color:'red'}}>invalid Price</div>
                }
                </div>
            </div>
            <div style={{width:matches?'348px':'100%',marginTop:15,padding:matches?'':'0 10px'}} className='flex justify-end items-center'>
              <div>Fee:</div>&nbsp;
              <div>{fee}% 
              </div>
            </div>
            {/* <div className='nft-box'>
              <div>Total price:</div><div>{percentileSegmentation(ipt1 && 100 * ipt1)} USDT</div>
            </div> */}
            <div className='btn btn-change'>
              <Button style={{border:'none'}} loading={lodaing} className='Cancle'  onClick={()=>{
                setOpenSell(false)
                setIsTest(false)
                setIsIdText(false)
                }}>Cancel</Button>
              <Button disabled={!toPrice||!toId||isTest||isIdText} style={{border:'none', color: '#000', opacity: !toPrice||!toId||isTest||isIdText ? 0.8 : 1}} loading={lodaing} onClick={()=>{
                sell()
                }}>Confirm</Button>
            </div>
          </BuyConfirm>
      </Modal>
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openMdfBuy} closable={false}>
          <MdfBuyConfirm isMobile={matches}>
            <div className='title'>Buy Confirm</div>
            <div className='nft-box'>
              <div>Number:</div><div><Input value={iptMore} onChange={e => handleChange(e.target.value, setIptMore)}/></div>
            </div>
            <div className='amount-box'>
              <div>Amount:</div><div>100 USDT</div>
            </div>
            <div className='btn btn-change'>
              <Button className='Cancle'  onClick={()=>{setOpenMdfBuy(false)}}>Cancel</Button>
              <Button  disabled={!iptMore}  style={!iptMore?{background: '#6d6d6d', border: 'none'}:{}} onClick={()=>{
                setIptMore(null)
                setOpenMdfBuy(false)
                }}>Confirm</Button>
            </div>
          </MdfBuyConfirm>
      </Modal>
      
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={boxOpenBuy} closable={false}>
          <BoxBuyConfirm isMobile={matches}>
            <div className='title'>Buy One MDF</div>
            <div className='btn btn-change'>
              <Button className='Cancle'  onClick={()=>{setBoxOpenBuy(false)}}>Cancel</Button>
              <Button onClick={()=>{
                setBoxOpenBuy(false)
                }}>Confirm</Button>
            </div>
          </BoxBuyConfirm>
      </Modal>
    </Wrapper>
  )
}
