/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/export */
import {useState,useEffect} from 'react'
import useToast from 'hooks/useToast'
import { nanoid } from 'nanoid';
import { Decimal } from "decimal.js";
import { getNFTsStakingForSynchronous } from 'utils/utils'
import { Modal } from 'antd'
import { formatDecimal } from 'utils/math'
import { Button } from '@dreamweb/uikit'
import Login from 'components/Login'
import Earend from './components/Earend'
import { Card, CenterBox } from './indexStyles'
import Tokens from './components/Tokens'


const horse0 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse0.mp4"
const horse1 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse1.mp4"
const horse2 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse2.mp4"
const horse3 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse3.mp4"
const horse4 = "https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/video/pegasus/horse4.mp4"

const horseArr = [horse0,horse1,horse2,horse3,horse4]


const result = sessionStorage.getItem('gas') || 50000
export default function CardBox({
  item, matches, tabIndex, NftStakingContract, pegasusNftContract, mdfNftContract,
  account, isApproveNM, nftApprove, getAllData, NFTLenghtG, lodaing
}:any) { 
  const [isLoading,setIsloading] = useState(false)
  const [selectType, setSelectType]=useState<any>(0)
  const [selectType1, setSelectType1]=useState<any>(0)
  const [isAdd,setIsAdd] = useState(false)
  const [isAdd1,setIsAdd1] = useState(false)
  const [alltotal,setAllTotal]=useState(0)
  const [total,setTotal]=useState(0)
  const [total1,setTotal1]=useState(0)
  const [isStackToken, setIsStackToken] = useState(false)
  const [isStackToken1, setIsStackToken1] = useState(false)
  const [allIdvs,setAllIdvs]=useState<any>()
  const [singleIds,setSingleIds]=useState([])
  const [idvs,setIdvs]=useState<any>()
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const arrPegasusName=['Unicorn',
    'Pegasus Normal',
    'Pegasus Bronze',
    'Pegasus Silver',
    'Pegasus Gold']
  const arrMDFName = ['MDF Normal',
    'MDF Premium']


  // 获取当前展示
  useEffect(() => { 
    if(account) {
      getUserTokens()
      // getStakingTokens()
    }
  }, [account,tabIndex])

  
  // 获取全部NFT数据 view
  const getUserTokens = async () => { 
    try {
      setAllTotal(0)
      setAllIdvs({
        ids: [],
        lvs: []
      })
      let term1 = {
        ids: [],
        lvs: []
      }
      if(tabIndex===0) {
        // console.log(83)
        term1 = await getNFTsStakingForSynchronous(pegasusNftContract, account)  
        // console.log(84, term1)  
        // term1 = await pegasusNftContract.methods.getUserTokens(account,500,1).call()
      } else {
        term1 = await getNFTsStakingForSynchronous(mdfNftContract, account)   
        // term1 = await mdfNftContract.methods.getUserTokens(account,500,1).call()
      }
      // console.log(88, account, term1)
      const lvs = term1?.lvs; const ids= term1?.ids
      setAllTotal(ids?.length)
      // console.log("当前展示页数据", {
      //   ids: [...ids],
      //   lvs: [...lvs]
      // })
      setAllIdvs({
        ids: [...ids],
        lvs: [...lvs]
      })
    } catch (error) {
      // console.log(99, account, error)
      // getUserTokens()
    }
    
  }

  
  // 获取当前点击NFT
  const chlickNFT = async (id) => {
    // console.log(219, id, allIdvs)
    const term1 = allIdvs
    if(tabIndex===0) {
      // 'Unicorn'
      if(id===0) {
        const lvs = []; const ids=[]
        for (let index = 0; index < term1?.lvs?.length; index++) {
          if(term1?.lvs?.[index]==='0') {
            ids.push(term1?.ids?.[index]);
            lvs.push(term1?.lvs?.[index]);
          }
        }
        setIdvs({
          ids:[...ids],
          lvs:[...lvs]
        })
        // setTotal(lvs?.length)
        return
      }
      // 'Pegasus Normal'
      if(id===1) {
        const lvs = []; const ids=[]
        for (let index = 0; index < term1?.lvs?.length; index++) {
          if(term1?.lvs?.[index]==='1') {
            ids.push(term1?.ids?.[index]);
            lvs.push(term1?.lvs?.[index]);
          }
        }
        setIdvs({
          ids:[...ids],
          lvs:[...lvs]
        })
        // setTotal(lvs?.length)
        return
      }
      // 'Pegasus Bronze'
      if(id===2) {
        const lvs = []; const ids=[]
        for (let index = 0; index < term1?.lvs?.length; index++) {
          if(term1?.lvs?.[index]==='2') {
            ids.push(term1?.ids?.[index]);
            lvs.push(term1?.lvs?.[index]);
          }
        }
        setIdvs({
          ids:[...ids],
          lvs:[...lvs]
        })
        // setTotal(lvs?.length)
        return
      }
      // 'Pegasus Silver'
      if(id===3) {
        const lvs = []; const ids=[]
        for (let index = 0; index < term1?.lvs?.length; index++) {
          if(term1?.lvs?.[index]==='3') {
            ids.push(term1?.ids?.[index]);
            lvs.push(term1?.lvs?.[index]);
          }
        }
        setIdvs({
          ids:[...ids],
          lvs:[...lvs]
        })
        // setTotal(lvs?.length)
        return
      }
      // 'Pegasus Gold'
      if(id===4) {
        const lvs = []; const ids=[]
        for (let index = 0; index < term1?.lvs?.length; index++) {
          if(term1?.lvs?.[index]==='4') {
            ids.push(term1?.ids?.[index]);
            lvs.push(term1?.lvs?.[index]);
          }
        }
        // setTotal(ids?.length)
        setIdvs({
          ids:[...ids],
          lvs:[...lvs]
        })
        
      }

    }else {
      // 'MDF Normal'
      if(id===0) {
        const lvs = []; const ids=[]
        for (let index = 0; index < term1?.lvs?.length; index++) {
          if(term1?.lvs?.[index]==='0') {
            ids.push(term1?.ids?.[index]);
            lvs.push(term1?.lvs?.[index]);
          }
        }
        // setTotal(lvs?.length)
        setIdvs({
          ids:[...ids],
          lvs:[...lvs]
        })
        return
      }
      // 'MDF Premium'
      if(id===1) {
        const lvs = []; const ids=[]
        for (let index = 0; index < term1?.lvs?.length; index++) {
          if(term1?.lvs?.[index]==='1') {
            ids.push(term1?.ids?.[index]);
            lvs.push(term1?.lvs?.[index]);
          }
        }
        // setTotal(lvs?.length)
        setIdvs({
          ids:[...ids],
          lvs:[...lvs]
        })
        
      }
    }
  }  

    
  // 查询已质押的卡片
  const getStakingTokens = async (v) => { 
    try {
      // console.log('查询已质押的卡片',tabIndex,v,account,500,1)
      const arrSingleIds = await NftStakingContract.methods.getUserSingleStakingTokens(tabIndex,v,account,500,1).call()
      setSingleIds(arrSingleIds)
      setTotal1(arrSingleIds?.length)
      // console.log('查询已质押的卡片ids', arrSingleIds)
    } catch (error) {
      // console.log(237,)
    }
  }


  
  // 质押  stake
  const deposit = async (v) => { 
    try {
      if(tabIndex===0) {
        console.log(`stakePegasus(${selectType},[${[...idvs?.ids]?.splice(0,v||0)}])`,[...idvs?.ids]?.splice(0,v||0))
        await NftStakingContract.methods.stakePegasus(selectType,idvs?.ids?.splice(0,v||0)).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        })
      } else {
        console.log(`MDF(${selectType},[${[...idvs?.ids]?.splice(0,v||0)}])`,selectType,[...idvs?.ids]?.splice(0,v||0))
        await NftStakingContract.methods.stakeMDF(selectType,[...idvs?.ids]?.splice(0,v||0)).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          
        })
      }
      toastSuccess('Stake successful')
      getUserTokens()
      setIsStackToken(false)
      getAllData(NFTLenghtG)
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Stake failed')
    }
  }
      
  // 取回 Unstake
  const withdraw = async (v) => { 
    const arrv = [...new Array(+v)]?.map((item,index) => { 
      return 0
     })
    // console.log('取回参数',singleIds,arrv)
    try {
      if(tabIndex===0) {
        console.log('withdrawPegasus取回参数', selectType1,[...singleIds]?.splice(0,v), arrv)
        await NftStakingContract.methods.withdrawPegasus(selectType1,[...singleIds]?.splice(0,v),arrv).send({
          from: account, 
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          
        })
      } else {
        console.log('withdrawMDF取回参数', selectType1,[...singleIds]?.splice(0,v), arrv)
        await NftStakingContract.methods.withdrawMDF(selectType1,[...singleIds]?.splice(0,v),arrv).send({
          from: account, 
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
          
        })
      }
      toastSuccess('Unstake successful')
      getUserTokens()
      setIsStackToken1(false)
      getAllData(NFTLenghtG)
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Unstake failed')
    }
  }

  
  return (
    <>
    <CenterBox key={nanoid()} isMobile={matches}>
      <Card isMobile={matches}>
        <div className='title'>{
          tabIndex===0?
          <>
            {
              arrPegasusName?.[item?.id]
            }
          </>:
          <>
          {
            arrMDFName?.[item?.id]
          }
          </>
        }</div>
        <div className='content-box' 
                >
          <div className='img'>
            {
              +tabIndex===0 && sessionStorage.getItem('navigatorName') === '-1' ? 
              <video
                x5-playsinline 
                webkit-playsinline 
                x5-video-player-type="h5" 
                x5-video-player-fullscreen
                x5-video-orientation="portraint" 
                playsInline loop autoPlay muted controls={false}>
                  <source src={horseArr?.[item?.id]}/>
              </video> :
              <img src={`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/NFTs/horse${tabIndex===0?item?.id:item?.id===0?5:6}.png`} alt="" />
              
            }
          </div>
          <div className='content'>
            <div className='text-box'>
              <div style={{color: '#8A8A8A'}}>Total Staking</div>
              <div style={{color: '#EEEEEE'}}>{formatDecimal(item?.totalStaking?.totalStake||0,4)}</div>
            </div>
            <div className='text-box'>
              <div style={{color: '#8A8A8A'}}>APY</div>
              <div style={{color: '#EEEEEE'}}>{new Decimal(+item?.apy||0).div(100).toNumber()}%</div>
            </div>
            <div className='text-box'>
              <div style={{color: '#8A8A8A'}}>My Staked</div>
              <div style={{color: '#EEEEEE'}}>{item?.staked||0}</div>
            </div>
            <div className='text-box'>
              <div style={{color: '#8A8A8A'}}>Earned</div>
            </div>
            <Earend tabIndex={tabIndex} item={item} isLoading={isLoading} NftStakingContract={NftStakingContract} account={account}/>
            <div className='btn'>
              {
                account ? 
                <>
                {
                  isApproveNM ? 
                  <>
                  {
                    +item?.staked ? 
                    <div style={{position:'relative',width:'100%', height: '30px'}}>
                      <Button 
                      // disabled={item?.total<1} 
                        isLoading={isLoading} 
                        onClick={()=>{
                        // history.push({pathname: '/nft/pegasus-nft',state:{selectType: 'Unicorn'}})
                        setSelectType(item?.id)
                        chlickNFT(item?.id)
                        setIsAdd(true)
                        setIsStackToken(true)
                        setTotal(item?.total)
                        }} style={{color: "#000000", width: '43%', height: '30px', background: '#FFFFFF', borderRadius: '4px', position:'absolute',left: '0px'}}>
                        Stake
                      </Button>
                      <Button  isLoading={isLoading} onClick={()=> {
                        setSelectType1(item?.id)
                        getStakingTokens(item?.id)
                        setIsStackToken1(true)
                        setIsAdd1(false)
                        // history.push({pathname: '/staking/un-stake/pegasus',state:{selectType: 'Unicorn'}})
                      }} style={{color: "#000000", width: '43%', height: '30px', background: '#FFFFFF', borderRadius: '4px', position:'absolute',right: '0px'}}>
                        Unstake
                      </Button>
                    </div> :
                    <Button 
                    // disabled={item?.total<1} 
                    isLoading={isLoading} 
                      onClick={()=>{
                        setSelectType(item?.id)
                        chlickNFT(item?.id)
                        setIsStackToken(true)
                        setIsAdd(true)
                        setTotal(item?.total)
                      }} style={{color: "#000000", width: '100%', height: '30px', background: '#FFFFFF', borderRadius: '4px'}}>
                      Stake
                    </Button>
                  }
                  </>
                  : 
                  <Button 
                    isLoading={lodaing}
                    onClick={async ()=>{
                      await nftApprove()
                    }}
                    style={{color: "#000000", width: '100%', height: '30px', background: '#FFFFFF', borderRadius: '4px'}}>
                    Approve
                  </Button>
                }
                </> :
                <Login
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                >
                  <div style={{width: '100%',height: '30px',background: '#fff', borderRadius: '4px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
                </Login>
              }
            </div>
          </div>
        </div>
      </Card>
    </CenterBox>
    <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken} closable={false}>
      {
        isStackToken &&
        <Tokens matches={matches} id={item?.id} tabIndex={tabIndex} staked={total} idx={selectType} isAdd={isAdd} onDismiss={()=>setIsStackToken(false)} 
          deposit={async (v)=>{
            await deposit(v)
            // 更新
          }}
          withdraw={async (v)=>{
            await withdraw(v)
            // 更新
          }}/>
      }
    </Modal>
    <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken1} closable={false}>
      {
        isStackToken1 &&
        <Tokens  matches={matches} id={item?.id} tabIndex={tabIndex} staked={total1} idx={selectType1} isAdd={isAdd1} onDismiss={()=>setIsStackToken1(false)} 
          deposit={async (v)=>{
            await deposit(v)
            // 更新
          }}
          withdraw={async (v)=>{
            await withdraw(v)
            // 更新
          }}/>
      }
    </Modal>
  </>
  )
}

